<template>
  <div class="pay-container">
    <template v-if="model == 0">
      <el-dialog width="300px" title="支付" center :visible.sync="mapVisible" :close-on-click-modal="false"
        :before-close="dialogBeforeCloseFun">
        <div class="pay-body">
          <div class="pay-info">
            <!-- <h3 class="pay-title">微信支付</h3> -->
            <h3 class="pay-price">
              <span class="price-unit">￥</span>
              <span class="price-text">{{price}}</span>
            </h3>
            <p class="pay-name" v-if="payee">{{payee}}</p>
          </div>
          <div class="pay-main">
            <div class="pay-qrcode" :class="{'toggle-qrcode': !showQrCode && type == 2}">
              <img class="fake-img" :src="qrCode" alt="佰团乐小程序二维码">
              <!-- <el-image
                class="fake-img"
                name="支付"
                :src="qrCode"
                fit="scale-down"
              ></el-image> -->
              <!-- <iframe
                ref="iframe"
                class="iframe-box"
                :src="qrCode"
              ></iframe> -->
            </div>
<!--            <p class="pay-qrcode-text">请使用{{payMode}}扫一扫付款</p>-->
            <p class="pay-qrcode-text">请使用法人或经办人{{payMode}}扫一扫付款</p>
            <div class="pay-rest">
              <div class="vip-terms-service" v-if="type == 2">
                <div class="checkbox-box">
                  <el-checkbox v-model="showQrCode">
                    <div class="cb-main">
                      支付即代表您同意佰团乐
                      <a class="fake-a" :href="ossUrl + '/images/base-servers/会员服务协议.pdf'" target="_blank">《会员服务协议》</a>
                    </div>
                  </el-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
    </template>
    <template v-else>
      <template v-if="model == 1">
        <el-dialog width="300px" title="支付" center :visible.sync="mapOptions.visible" :close-on-click-modal="false"
          v-if="mapOptions.visible" :before-close="dialogBeforeCloseFun">
          <div class="pay-body">
            <div class="pay-info">
              <h3 class="pay-title" v-if="mapOptions.title.value" :style="mapOptions.title.style">
                {{mapOptions.title.value}}</h3>
              <h3 class="pay-price">
                <span class="price-unit" :style="mapOptions.price.unit.style">{{mapOptions.price.unit.value}}</span>
                <span class="price-text" :style="mapOptions.price.price.style">{{mapOptions.price.price.value}}</span>
              </h3>
              <p class="pay-name" v-if="mapOptions.payee.value">{{mapOptions.payee.value}}</p>
            </div>
            <div class="pay-main">
              <div class="pay-qrcode" :style="mapOptions.qrCode.style"
                :class="{'toggle-qrcode': !showQrCode && mapOptions.qrCode.status != 0}">
                <img class="fake-img" :src="mapOptions.qrCode.value" alt="佰团乐小程序二维码">
              </div>
              <p class="pay-qrcode-text" v-if="mapOptions.descriptions.value" :style="mapOptions.descriptions.style">
                {{mapOptions.descriptions.value}}</p>
              <div class="pay-rest">
                <div class="vip-terms-service" v-if="mapOptions.qrCode.status != 0">
                  <div class="checkbox-box">
                    <el-checkbox v-model="showQrCode">
                      <div class="cb-main">
                        支付即代表您同意佰团乐
                        <a class="fake-a" :href="ossUrl + '/images/base-servers/会员服务协议.pdf'"
                          target="_blank">《会员服务协议》</a>
                      </div>
                    </el-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-dialog>
      </template>
    </template>
  </div>
</template>

<script>
  import {
    cerateFileNameFun
  } from '@/utils/utils_fun.js';
  import MyVueCropper from '@/components/my-vue-cropper/MyVueCropper.vue';
  export default {
    // 允许组件模板递归地调用自身
    name: 'MyTailorDialog',
    // 声明一组可用于组件实例中的组件
    components: {
      MyVueCropper,
    },
    // 一个用于从父组件接收数据的数组或对象
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      model: { // 弹窗模型
        type: [Number, String],
        default: 0, // 默认
      },
      payee: {
        type: String,
        default: '',
      },
      price: {
        type: [String, Number],
        default: '',
      },
      qrCode: {
        type: String,
        default: '',
      },
      type: {
        type: [String, Number],
        default: '1', // 1/默认 2/vip
      },
      payMode: {
        type: [String, Number],
        default: '微信',
      },
      options: { // 统一选项
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    // 该函数返回组件实例的 data 对象
    data() {
      return {
        ossUrl: this.GLOBAL.ossUrl,
        showQrCode: false, // 显示二维码
        mapVisible: this.visible,
        mapQrCode: 'https://test.baituanle.com/index.php?s=/index/qrcode/index/content/d2VpeGluOi8vd3hwYXkvYml6cGF5dXJsP3ByPUNudW9hdEt6eg%253D%253D.html',
        mapOptions: this.options,
      }
    },
    // 计算属性：
    computed: {

    },
    // 钩子函数--侦听data变动：
    watch: {
      visible: function (e) {
        this.mapVisible = e;
        console.log('支付组件 this.qrCode == ', this.qrCode)
        var textDoc1 = window.top.document.getElementById('iframe')
        console.log('iframe textDoc1 == ', textDoc1)
        if (textDoc1) {
          let iframe = textDoc1.contentWindow;
          // let iframe = textDoc1[0].contentWindow.document.body;
          // let iframe = textDoc1[0].contentWindow;
          console.log('iframe == ', iframe)
        }
      },
      options: function (e) {
        console.log('this.options == ', this.options)
        // 初始化-支付弹窗选项
        this.initOptionsFun(e);
      }
    },
    // 在实例创建完成后被立即同步调用
    methods: {
      /**
       * 初始化-支付弹窗选项
       */
      initOptionsFun(opt) {
        opt = opt || this.options;

        let options = {
          visible: false,
          title: {
            value: '',
            style: '',
          },
          descriptions: {
            name: '微信',
            value: ' 请使用微信扫一扫付款',
            style: '',
          },
          payee: {
            value: ' ',
            style: '',
          },
          price: {
            price: {
              value: '',
              style: '',
            },
            unit: {
              value: '￥',
              style: '',
            },
          },
          qrCode: {
            value: '',
            style: '',
            status: 0,
          }
        };
        this.mapOptions = Object.assign(options, opt);
        console.log('初始化-支付弹窗选项 this.mapOptions == ', this.mapOptions)
      },
      /**
       * 关闭弹窗前
       */
      dialogBeforeCloseFun(done) {
        done();
        console.log('关闭弹窗前 == ', this.mapVisible)
        // 告知弹窗变化
        this.informChangeFun();
      },
      /**
       * 告知弹窗变化
       */
      informChangeFun(opt) {
        let default_data = {
          visible: false,
          status: 3, // 1/支付成功 2/取消支付 3/取消支付，关闭弹窗
          message: '取消支付，关闭弹窗',
          data: '',
        };
        let result = Object.assign(default_data, opt);
        this.$emit('on-change', result)
      },
    },
    // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
    created() {

    },
    // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
    mounted() {

    },
    beforeCreate() { // 创建前

    },
    beforeMount() { // 挂载前

    },
    beforeUpdate() { // 更新前

    },
    updated() { // 更新后

    },
    beforeDestroy() { // 销毁前

    },
    destroyed() { // 销毁后

    },
    activated() { // 缓存保持，需要由 keep-alive 触发

    },
  }

</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
  .pay-container {
    box-sizing: border-box;

    &::v-deep {
      .el-dialog {
        border-radius: 10px;
      }

      .el-dialog__header {
        padding: 0;

        .el-dialog__title {
          display: none;
        }
      }

      // .el-dialog__body {
      //   padding: 50px 20px;
      // }
      .el-dialog__headerbtn {
        width: 24px;
        height: 24px;
        text-align: center;
        font-size: 24px;
        top: 10px;
        right: 10px;
      }
    }
  }

  .pay-body {
    box-sizing: border-box;
    padding-top: 10px;

    .pay-info {
      .pay-price {
        text-align: center;
        font-size: 24px;
        color: $common-number1-color;

        .price-unit {}

        .price-text {
          font-size: 36px;
        }

      }

      .pay-name {
        margin-top: 3px;
        text-align: center;
        font-size: 14px;
        color: #444343;
      }
    }

    .pay-main {

      .pay-qrcode {
        margin: 24px auto 0;
        width: 186px;
        height: 186px;

        // height: 210px;
        .fake-img {
          width: 100%;
          height: 100%;
          object-fit: scale-down;
        }

        &.toggle-qrcode {
          position: relative;
          overflow: hidden;

          .iframe-box,
          .fake-img {
            filter: blur(7px);
          }

          &::after {
            content: '';
            display: block;
            z-index: 2;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
        }

        .iframe-box {
          width: 100%;
          height: 100%;
          overflow: visible;
          border: none;
          // background: #fff;
          // background: #f5f5f5;

          &::v-deep {

            html,
            body {
              overflow: hidden;
            }
          }
        }

      }

      .pay-qrcode-text {
        margin-top: 6px;
        text-align: center;
        font-size: 13px;
        color: #787878;
      }
    }
  }

  .vip-terms-service {
    margin-top: 16px;
    text-align: center;

    .checkbox-box {
      $height: 52px;
      $checkbox-height: 16px;
      display: inline-block;
      width: auto;
      margin: auto;

      &::v-deep {
        .el-checkbox {
          &::after {
            content: '';
            display: block;
            clear: both;
          }
        }

        .el-checkbox {
          position: relative;
          width: 100%;
          box-sizing: border-box;
          // padding-right: 38px;
        }

        .el-checkbox__inner {
          width: $checkbox-height;
          height: $checkbox-height;
          border-color: #AAAAAA;
          border-radius: 50%;
          overflow: hidden;

          &::after {
            // height: 10px;
            left: 5px;
            top: 2px;
          }
        }

        .el-checkbox__input {

          // float: right;
          // position: absolute;
          // top: 50%;
          // right: 0;
          // transform: translateY(-50%);
          &.is-focus {
            .el-checkbox__inner {
              // border-color: $common-number1-color;
              border-color: #AAAAAA;
            }
          }
        }

        .el-checkbox__label {
          // float: left;
          padding: 0;
        }

        .el-checkbox__input.is-checked .el-checkbox__inner,
        .el-checkbox__input.is-indeterminate .el-checkbox__inner {
          background: $common-number1-color;
          border-color: $common-number1-color;
        }
      }

      .cb-main {
        box-sizing: border-box;
        padding-left: 10px;
        font-size: 12px;
        color: #AAAAAA;

        .fake-a {
          font-size: 12px;
          color: #409EFF;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

</style>
