import Vue from 'vue'
import { getUrlInfo } from '@/utils/utils_fun'
import ShareWindow from '@/utils/share_window';

// let shareWin;

// 路由监控-二级域名跳转
const routerMonitoring = (to,from) => {

  const vue_this = Vue.prototype;
  let { name: sld_name, url: sld_url } = vue_this.GLOBAL.sldInfo.aUrl; // 二级域名：https://btl.lyybao.cn
  let { name: main_name, url: main_url } = vue_this.GLOBAL.mainDomainNameInfo.aUrl; // 二级域名：https://btl.baituanle.com
  // main_name = '192.168.2.250:8080';
  // main_url = 'http://192.168.2.250:8080';

  console.log('保险模块 ================= start ');
  let location = getUrlInfo();
  console.log('当前域名信息 location == ',location)
  console.log('断点 to -------------- ',to);
  console.log('vue_this.GLOBAL to -------------- ',vue_this.GLOBAL);

  // 规则匹配-保险模块
  let regexp1 = /insurance/g;

  // 是保险模块
  if(regexp1.test(to.fullPath)) {
    let to_fullPath = to.fullPath;
    if(sld_name) {
      if(location.origin.indexOf(sld_name) == -1) {
        // // 共享缓存到保险页面
        // if(vue_this.shareWin) {
        //   vue_this.shareWin.sandPostMessage(vue_this.shareWin.childWin)
        // }
        window.location.replace(sld_url + to_fullPath);
        return;
      }
    }
  } else { // 其他模块
    let to_fullPath = to.fullPath;
    if(main_name) {
      if(location.origin.indexOf(main_name) == -1) {
        // if(vue_this.shareWin) {
        //   vue_this.shareWin.sandPostMessage(vue_this.shareWin.childWin)
        // }
        // // 如果是跳转保险页面，则先将数据共享再执行跳转
        // if(to.path == '/insurance') {
        //   // // 共享缓存到保险页面
        //   if (_this.shareWin) {
        //     _this.shareWin.sandPostMessage(_this.shareWin.childWin)
        //   }
        // }
        window.location.replace(main_url + to_fullPath);
        return;
      }
    }
  }
  console.log('保险模块 ================= end ');


  if(!vue_this.shareWin) {
    console.log('shareWin1 window location.origin == ',location.origin)
    if(sld_name && (location.origin.indexOf(sld_name) == -1)) {
      console.log('shareWin1 sld_name == ',sld_name)
      vue_this.shareWin = new ShareWindow(sld_url + '/insurance',sld_name);
      vue_this.shareWin.init();
    } else if(main_name && (location.origin.indexOf(main_name) == -1)) {
      console.log('shareWin1 main_name == ',main_name)
      vue_this.shareWin = new ShareWindow(main_url + '/',main_name);
      vue_this.shareWin.init();
    }
    // shareWin.sandPostMessage(shareWin.parentWin,'[{"key":"time main","val":"time main"}]')
    // setTimeout(() => {
      // shareWin.sandPostMessage(shareWin.childWin)
      // shareWin.sandPostMessage(shareWin.childWin,'[{"key":"time2 main","val":"time2 main"}]')
    //   vue_this.shareWin.sandPostMessage(vue_this.shareWin.childWin)
    // },300)
  }


}

export {
  routerMonitoring
}
