<template>
  <div class="container">
    <!-- <h1>全局变量：{{ global_url }}</h1>
    <h1>全局表达式：{{ global_regexp }}</h1> -->
    <div class="banner-swiper">
      <el-carousel class="swiper-container" trigger="click" :height="bannerHeight + 'px'">
        <el-carousel-item class="swiper-item" v-for="(item,index) in banner" :key="index">
          <div class="swiper-main">
            <el-image ref="banner" :name="item.name" :src="item.images_url" fit="scale-down"></el-image>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="floor">
      <!-- <div class="floor-bg">
        <div class="bg-one">
          <div class="adorn1 diff-style"></div>
          <div class="adorn2"></div>
          <div class="adorn1"></div>
          <div class="adorn3"></div>
        </div>
        <div class="bg-two"></div>
      </div> -->
      <div class="floor-body">
        <!-- <div class="module1">
          <temp-title title="这些行业可以用我们" descriptions="THESE INDUSTRIES CAN USE US" />
          <div class="module1-container">
            <div class="introduce">
              <div class="title">{{industries.list[industries.current].title}}</div>
              <div class="descriptions">
                <p>{{industries.list[industries.current].p_one}}</p>
                <p>{{industries.list[industries.current].p_two}}</p>
              </div>
            </div>
            <div class="floor-swiper">
              <el-carousel class="swiper-container" trigger="click" height="500px" @change="floorSwiperChange">
                <el-carousel-item class="swiper-item" v-for="(item,index) in industries.list" :key="index" >
                  <div class="swiper-main">{{item.title}}</div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div> -->

<!--        <div class="module6">
          <temp-title title="保险，我们可以做的更好" descriptions="INSURANCE, WE CAN DO BETEER" />
          <div class="module6-container">
            <ul class="list">
              <li class="item" v-for="(item,index) in insurance" :key="index" v-show="index < 4">
                <router-link :to="'/insurance/index?id='+item.id" class="details">
                  <div class="link">
                    <div class="cover">
                      <el-image :src="item.images[0]" fit="scale-down"></el-image>
                    </div>
                    <div class="info">
                      <div class="info-place">
                        <h2 class="title">{{item.name}}</h2>
                        <p class="descriptions">{{item.subhead}}</p>
                        <div class="price">
                          <span class="price-text">￥{{item.plan[0].time_limit[0].money}}</span>
                          <span class="price-unit">/起</span>
                        </div>
                        <div class="details">
                          <span>查看详情</span>
                          <i class="iconfont icon-arrow-right-1-icon"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
        </div>-->

        <div class="module2">
          <temp-title title="高质量活动、场地任您选择" descriptions="HIGH QUALITY ACTIVITIES AND CEVNUES" />
          <div class="module2-container">
            <ul class="list">
              <li class="item" v-for="(item,index) in active_site" :key="index">
                <div class="link">
                  <div class="mark" :style="'background-image:url(\'' + ossUrl + '/images/home-img/module-icon.png\')'">
                    <p class="mark-text">优选</p>
                    <p class="mark-text" v-if="index == 0">场地</p>
                    <p class="mark-text" v-else>活动</p>
                  </div>
                  <div class="cover">
                    <el-image :src="item.url" fit="scale-down"></el-image>
                  </div>
                  <div class="info">
                    <div class="title">
                      <!-- <em class="type-tag">秒杀</em> -->
                      <h2 class="title-text">{{item.title}}</h2>
                    </div>
                    <ul class="tag-list">
                      <li class="tag-item" v-for="(citem,cindex) in item.labels" :key="cindex">{{citem}}</li>
                    </ul>
                    <div class="rest">
                      <div class="site">
                        <span class="key">地点：</span>
                        <span class="val">{{item.site}}</span>
                      </div>
                      <!-- <div class="price">
                        <span class="key">活动价格：</span>
                        <span class="diff-val">￥188</span>
                      </div>
                      <div class="details">
                        <span>查看详情</span>
                        <i class="iconfont icon-arrow-right-1-icon"></i>
                      </div> -->
                    </div>
                  </div>
                </div>
              </li>
              <li class="item" v-if="clientSource !== 'baiduboxapp'">
                <div class="link diff-link">
                  <p>手机微信扫一扫</p>
                  <p>去小程序查看更多优质活动场地吧！</p>
                  <div class="photo">
                    <el-image :src="ossUrl + '/images/logo-img/applet-qrcode.jpg'" fit="scale-down"
                      :preview-src-list="[ossUrl + '/images/logo-img/applet-qrcode.jpg']"></el-image>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="module4">
          <temp-title title="团建活动定制服务" descriptions="CUSTOMIZED SERVICES FOR LEAGUE BUILDING ACTIVITIES" />
          <!-- <div class="module-container" v-if="false">
            <el-image
              :src="ossUrl + '/images/home-img/flow.png'"
              fit="scale-down"
            ></el-image>
          </div> -->
          <div class="module-container">
            <custom-servers></custom-servers>
          </div>
        </div>

        <div class="module3">
          <div class="module3-container">
            <div class="photo">
              <div class="border"></div>
              <el-image :src="ossUrl + '/images/home-img/about-img.jpg'" fit="cover"></el-image>
            </div>
            <div class="content">
              <temp-title title="关于我们" descriptions="ABOUT US" title-color="#fff" descriptions-color="#fff" />
              <p class="text">
                佰团乐体验教育多元化服务平台是以企业团建、营地教育为内容的教育旅游平台，通过链接社会和产业上下游资源，为教育、旅游各环节提供信息、功能和增值服务，解决信息不对等的问题，最终实现教育、旅游内容变现的互联网第三方服务平台。
              </p>
              <p class="text">
                使命：为教育产业赋能，助力让每一个人每一个组织成就不凡
              </p>
              <p class="text">
                愿景：做一流的教育、旅游服务平台
              </p>
              <p class="text">
                价值观：相互成就 团结奋进 开拓创新 追求卓越
              </p>
            </div>
          </div>
        </div>

        <div class="module5">
          <temp-title title="平台优势" descriptions="PLATFORM ADVANTAGES" />
          <div class="module-container">
            <ul class="advantages-list">
              <li class="al-item" v-for="(item,index) in advantages" :key="index">
                <div class="al-bg" :style="'background-image: url(\'' + item.url +'\')'"></div>
                <div class="al-content">
                  <i class="al-number">0{{(index + 1)}}</i>
                  <div class="al-icon">
                    <p class="icon-title">{{item.title}}</p>
                    <div class="icon-box">
                      <i class="iconfont" :class="item.icon"></i>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import {
    debounce
  } from '@/utils/utils_fun';
  import tempTitle from '@/views/home/components/temp-title';
  import customServers from '@/views/home/components/custom-servers';
  export default {
    // 允许组件模板递归地调用自身
    name: 'Home',
    // 声明一组可用于组件实例中的组件
    components: {
      tempTitle,
      customServers
    },
    // 一个用于从父组件接收数据的数组或对象
    props: {

    },
    // 该函数返回组件实例的 data 对象
    data() {
      return {
        clientSource: this.GLOBAL.clientSource,
        global_url: this.GLOBAL.baseUrl,
        global_regexp: this.REGEXP.is_number,
        ossUrl: this.GLOBAL.ossUrl,
        bannerHeight: "",
        page_data: {}, // 页面数据
        banner: [], // banner 轮播图
        active_site: [], // 活动、场地
        advantages: [], // 平台优势
        insurance: [], // 保险数据
        industries: {
          current: 0,
          list: [{
            title: '各类团建公司',
            p_one: '拥有各类资源的团建公司等可在平',
            p_two: '台认证，让公司提升曝光度，提升竞争力。',
          }, {
            title: '各类公司hr',
            p_one: '使用我们平台发布各类需求，让公',
            p_two: '司找到更合适的方案和场地。',
          }, {
            title: '专业的培训师',
            p_one: '各类教练、专业培训师等，都可以',
            p_two: '在平台上认证获取更多的客户资源。',
          }, {
            title: '优质的场地基地',
            p_one: '各类能进行户外团建培训的场地、',
            p_two: '基地入驻平台可获得更丰富的流量。',
          }, ],
        },
        timer: null, // 定时器
      }
    },
    // 计算属性：
    computed: {

    },
    // 钩子函数--侦听data变动：
    watch: {

    },
    // 在实例创建完成后被立即同步调用
    methods: {
      /**
       * 初始化数据
       */
      initPageDataFun() {
        let {
          banner_list
        } = this.page_data;
        console.log('初始化数据 this.page_data == ', this.page_data)
        console.log('初始化数据 banner_list == ', banner_list)
        return new Promise(resolve => {
          let ossUrl = this.ossUrl;

          // 设置banner图片
          this.banner = banner_list;
          // this.banner = [{
          //   url: ossUrl + '/images/home-img/swiper-img (2).jpg'
          // },{
          //   url: ossUrl + '/images/home-img/swiper-img (1).jpg'
          // },{
          //   url: ossUrl + '/images/home-img/swiper-img (3).jpg'
          // }];

          // 设置活动、场地图片
          this.active_site = [{
            title: '素质教育拓展基地',
            labels: ['爱国教育基地', '军事教育基地', '研学实践基地'],
            site: '广东省-深圳市',
            url: ossUrl + '/images/home-img/module-img (1).jpg'
          }, {
            title: '行走的力量 — 玄奘之路戈壁挑战赛',
            labels: ['理想', '行动', '坚持', '磨练'],
            site: '敦煌',
            url: ossUrl + '/images/home-img/module-img (4).jpg'
          }, {
            title: '趣味皮划艇',
            labels: ['团队融合', '放松减压', '感情升华'],
            site: '全国',
            url: ossUrl + '/images/home-img/module-img (2).jpg'
          }, {
            title: '激情非洲鼓',
            labels: ['团队融合', '鼓舞士气', '团队协作'],
            site: '全国',
            url: ossUrl + '/images/home-img/module-img (3).jpg'
          }];

          // 设置平台优势
          this.advantages = [{
            title: '在线接单',
            icon: 'icon-zaixianjiedan',
            url: ossUrl + '/images/home-img/advantage (2).jpg'
          }, {
            title: '覆盖率广',
            icon: 'icon-gongneng',
            url: ossUrl + '/images/home-img/advantage (3).jpg'
          }, {
            title: '精选商品',
            icon: 'icon-shangpinchuangjian',
            url: ossUrl + '/images/home-img/advantage (4).jpg'
          }, {
            title: '优质资源',
            icon: 'icon-ziyuan',
            url: ossUrl + '/images/home-img/advantage (5).jpg'
          }, {
            title: '专业权威',
            icon: 'icon-quanwei',
            url: ossUrl + '/images/home-img/advantage (1).jpg'
          }]



          resolve();
        });
      },
      /**
       * 初始化轮播图
       */
      initBannerFun() {

        let client_width = document.body.clientWidth;

        this.$nextTick(function () {
          // 获取窗口宽度*图片的比例，定义页面初始的轮播图高度
          console.log('client_width == ', client_width)
          this.bannerHeight = client_width * 690 / client_width;
          // this.bannerHeight = document.body.clientWidth * 1 / 4;
        });
      },
      /**
       * 监听窗口变化
       */
      listenerWindowsResizeFun() {
        // 监听窗口变化，使得轮播图高度自适应图片高度
        window.addEventListener("resize", debounce(this.windowChangeFun), false);
      },
      /**
       * 监听窗口执行函数
       */
      windowChangeFun() {
        if (this.$refs.banner) {
          console.log('this.$refs.banner == ', this.$refs.banner)
          if (this.$refs.banner[0]) {
            console.log('this.$refs.banner[0] == ', this.$refs.banner[0])
            let el_offsetHeight = this.$refs.banner[0].$el.offsetHeight;
            console.log('el_offsetHeight == ', el_offsetHeight)
            if (el_offsetHeight && el_offsetHeight > 0) {
              this.bannerHeight = el_offsetHeight;
              console.log('this.bannerHeight == ', this.bannerHeight)
              return true;
            }
          }
        }
        return false;
      },
      /**
       * 初始化轮播图高度-递归
       */
      initBannerHeightFun(index = 0) {
        let flag = this.windowChangeFun();
        console.log('初始化轮播图高度-递归 flag == ', flag)
        if (flag) return;
        if (index >= 10) {
          this.myMessage({
            message: '网络不好，请稍后再试！'
          })
        } else {
          this.timer = setTimeout(() => {
            this.initBannerHeightFun(index + 1);
          }, 1000)
        }
      },
      /**
       * 监听【行业使用】轮播图
       */
      floorSwiperChange(e) {
        this.industries.current = e;
      },
      /**
       * 获取页面数据
       */
      getPageDataFun() {


        // 监听窗口变化
        this.listenerWindowsResizeFun();

        // 获取首页数据
        this.getHomeDataFun().then(res => {
          console.log('首页数据 res == ', res);
          let resData = res.data.data;
          let {
            accommodation_list,
            banner_list,
            course_list,
            insurance,
            seckill,
            spell_group
          } = resData;

          // 获取保险数据
          this.insurance = insurance;
          this.page_data = resData;
          // 初始化数据
          this.initPageDataFun().then(() => {
            // 初始化 banner 轮播图
            this.initBannerFun();
          });
        });

        // 初始化轮播图高度-递归
        this.initBannerHeightFun();
      },
      /**
       * 获取首页数据
       */
      getHomeDataFun() {
        return this.myRequest({
          url: '/api/index/pcindex'
        })
        // .then(res => {
        //     console.log('首页数据 res == ',res);
        // }).catch(err => {
        //     console.log('首页数据 err == ',err);
        // })
      },
    },
    // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
    created() {

    },
    // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
    mounted() {
      this.getPageDataFun(); // 获取页面数据
    },
    beforeCreate() { // 创建前

    },
    beforeMount() { // 挂载前

    },
    beforeUpdate() { // 更新前

    },
    updated() { // 更新后

    },
    beforeDestroy() { // 销毁前
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null;
      }

      window.removeEventListener("resize", this.windowChangeFun);
    },
    destroyed() { // 销毁后

    },
    activated() { // 缓存保持，需要由 keep-alive 触发

    },
  }

</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
  .container {
    box-sizing: border-box;
  }

  .banner-swiper {

    .swiper-container,
    .el-carousel__item,
    .swiper-item,
    .swiper-main {
      width: 100%;
      height: 100%;

      & img,
      & el-image {
        width: 100%;
        height: 100%;
      }
    }

    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n+1) {
      background-color: #d3dce6;
    }
  }

  .floor {
    position: relative;
    overflow: hidden;

    .floor-bg {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      .bg-one {
        position: relative;
        display: flex;
        // height: 1500px;
        background-color: #fff;

        .adorn1 {
          flex: 1;
          min-width: 0;
          width: 260px;
          height: 390px;

          &.diff-style {
            background: #36547C;
          }
        }

        .adorn2 {
          // width: $theme-view-widht;
          max-width: $theme-view-widht;
          min-width: $theme-view-min-widht;
          height: 726px;
          margin: 0 auto;
          box-shadow: 4px 4px 7px -3px rgba(0, 0, 0, 0.3);
        }

        .adorn3 {
          width: 564px;
          height: 667px;
          background: #DFEBFB;
          position: absolute;
          right: 0;
          top: 104px;
        }
      }

      .bg-two {
        height: 550px;
        background-color: #DFEBFB;
      }

      // .bg-three {
      //   height: 300px;
      //   background-color: #fff;
      // }
    }

    .floor-body {
      box-sizing: border-box;
      padding: 0 $theme-widht-whiteedge;
      // width: $theme-view-widht;
      max-width: $theme-view-widht;
      min-width: $theme-view-min-widht;
      margin: 0 auto;

      .module1 {
        .module1-container {
          display: flex;
          align-items: center;
          box-sizing: border-box;
          padding: 0 50px;

          .introduce {
            flex: 1;
            min-width: 0;
            // display: flex;
            // flex-direction: column;
            // justify-content: center;
            text-align: center;

            .title {
              display: inline-block;
              width: auto;
              padding: 10px 20px;
              font-size: 20px;
              color: #444343;
              border: 1px solid #36547C;
            }

            .descriptions {
              margin-top: 25px;
              font-size: 16px;
              color: #aaa;

              p {
                margin-top: 5px;
              }
            }
          }

          .floor-swiper {
            width: 750px;

            .el-carousel__item:nth-child(2n) {
              background-color: #99a9bf;
            }

            .el-carousel__item:nth-child(2n+1) {
              background-color: #d3dce6;
            }
          }
        }
      }

      .module2 {
        margin-bottom: 100px;

        .module2-container {
          .list {
            &::after {
              content: '';
              display: block;
              clear: both;
            }

            .item {
              $gutter: 10px; // 间隔
              $height: 400px; // 高度
              float: left;
              // width: calc((100% / 3 - #{$gutter}) + (#{$gutter} / 3));
              // height: calc(#{$height} - (#{$gutter} / 2));
              width: 450px;
              height: 370px;
              margin-top: 17px;
              // margin-left: $gutter;
              margin-left: 13px;
              border-radius: 10px;
              overflow: hidden;
              background-color: #fff;
              box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.2);

              &:first-of-type {
                margin-left: 0px;
                width: 470px;
                height: 756px;

                // height: calc(#{$height} * 2);
                .link {
                  .cover {
                    height: 560px;
                  }

                  .info {
                    padding-top: 40px;
                  }
                }
              }

              &:nth-child(1),
              &:nth-child(2),
              &:nth-child(3) {
                margin-top: 0px;
              }

              .link {
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;

                .mark {
                  box-sizing: border-box;
                  padding-top: 6px;
                  z-index: 1;
                  position: absolute;
                  top: -1px;
                  left: 10px;
                  width: 34px;
                  height: 44px;
                  line-height: 1.2;
                  text-align: center;
                  font-size: 12px;
                  color: #fff;
                  background-repeat: no-repeat;
                  background-size: 100%;

                  .mark-text {
                    transform: scale(0.8);
                  }

                  &::v-deep {
                    .el-image {
                      width: 100%;
                      height: 100%;
                    }
                  }
                }

                .cover {
                  width: 100%;
                  height: 230px;
                  background-color: #99a9bf;
                  overflow: hidden;

                  &::v-deep {
                    .el-image {
                      width: 100%;
                      height: 100%;
                    }
                  }
                }

                .info {
                  box-sizing: border-box;
                  padding: 20px;
                  padding-top: 14px;
                  flex: 1;
                  min-height: 0;

                  .title {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 22px;
                    color: #444343;

                    .type-tag {
                      position: relative;
                      top: -2px;
                      display: inline-block;
                      box-sizing: border-box;
                      padding: 2px;
                      margin-right: 8px;
                      border: 1px solid #E62129;
                      border-radius: 4px;
                      font-size: 14px;
                      color: #E62129;
                    }

                    .title-text {
                      display: inline;
                      font-size: 22px;
                      color: #444343;
                    }
                  }

                  .tag-list {
                    // margin: 16px 0 32px;
                    margin: 16px 0;

                    &::after {
                      content: '';
                      display: block;
                      clear: both;
                    }

                    .tag-item {
                      float: left;
                      box-sizing: border-box;
                      padding: 2px 4px;
                      margin-right: 10px;
                      border: 1px solid #AAAAAA;
                      // border-radius: 4px;
                      font-size: 16px;
                      color: #AAAAAA;
                    }
                  }

                  .rest {
                    display: flex;
                    align-items: center;

                    .site,
                    .price {
                      flex: 1;
                      min-width: 0;
                      font-size: 18px;
                      color: #444343;

                      .diff-val {
                        font-size: 24px;
                        color: #E62129;
                      }
                    }

                    .details,
                    .details.iconfont {
                      font-size: 16px;
                      color: #E62129;
                    }
                  }
                }

                &.diff-link {
                  align-items: center;
                  justify-content: center;

                  &>p {
                    font-size: 18px;
                    color: #444343;
                    margin-bottom: 10px;
                  }

                  .photo {
                    width: 142px;
                    height: 142px;
                    margin-top: 20px;
                  }
                }
              }
            }
          }
        }
      }

      .module3 {
        margin: 100px 0;

        .module3-container {
          position: relative;
          box-sizing: border-box;
          padding: 100px 0;
          padding-left: 390px;

          .photo {
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 703px;
            height: 100%;
            background-color: #99a9bf;

            // box-shadow: 10px 5px 1px 0 rgba(0,0,0,0.2);
            .border {
              z-index: 2;
              position: absolute;
              top: 50%;
              right: -70%;
              transform: translate(-50%, -50%);
              width: 80%;
              height: 80%;
              border: 4px solid #1D232B;
            }

            &::v-deep {
              .el-image {
                width: 100%;
                height: 100%;
              }
            }
          }

          .content {
            z-index: 10;
            position: relative;
            box-sizing: border-box;
            padding: 0 80px 30px;
            background-color: #1D232B;

            .text {
              box-sizing: border-box;
              padding-top: 10px;
              text-indent: 2em;
              line-height: 2;
              font-size: 18px;
              color: #fff;
            }

            &::v-deep {
              .home-title-module {
                padding: 30px 0 15px;
              }
            }
          }
        }
      }

      .module4 {
        margin: 100px 0;
      }

      .module5 {
        margin: 100px 0;

        .module-container {
          .advantages-list {
            display: flex;
            justify-content: space-between;

            .al-item {
              position: relative;
              width: 19%;
              height: 370px;
              border-radius: 10px;
              overflow: hidden;

              &:hover {
                .al-bg {
                  filter: blur(0px);

                  &::after {
                    top: -110%;
                    left: -100%;
                  }
                }

                .al-content {
                  &::after {
                    top: -110%;
                    left: -100%;
                  }
                }
              }

              .al-bg {
                z-index: 1;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-position: center top;
                background-size: cover;
                filter: blur(3px);

                &::before {
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  width: 100%;
                  height: 100%;
                  background: rgba(0, 0, 0, 0.4);
                }

                &::after {
                  transition: all 0.6s;
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background: rgba(0, 0, 0, 0.6);
                  background: linear-gradient(-45deg, rgba(255, 255, 255, 0), rgba(0, 0, 0, 1));
                }
              }

              .al-content {
                z-index: 2;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                box-sizing: border-box;
                padding-bottom: 40px;
                height: 100%;

                .al-number {
                  position: absolute;
                  top: 14px;
                  left: 14px;
                  font-size: 30px;
                  color: rgb(255, 255, 255, 0.6);
                }

                .al-icon {
                  text-align: center;
                  color: #fff;

                  .icon-title {
                    font-size: 25px;
                    margin-bottom: 10px;
                  }

                  .icon-box {
                    .iconfont {
                      font-size: 50px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .module6 {
        margin-bottom: 100px;

        .module6-container {
          .list {
            display: flex;
            justify-content: space-around;

            .item {
              width: 340px;

              .link {
                .cover {
                  width: 100%;
                  height: 340px;
                  border-radius: 10px;
                  overflow: hidden;

                  // background-color: $theme-photo-background-color;
                  &::v-deep {
                    .el-image {
                      width: 100%;
                      height: 100%;
                    }
                  }
                }

                .info {
                  position: relative;
                  min-height: 140px;
                  text-align: center;

                  .info-place {
                    box-sizing: border-box;
                    padding: 12px;
                    min-height: 174px;
                    border-radius: 10px;
                    overflow: hidden;
                    background-color: #fff;
                    box-shadow: 0 0 14px -7px rgba(0, 0, 0, 0.6);
                    position: absolute;
                    bottom: 0;
                    left: 20px;
                    right: 20px;
                  }

                  .title {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-weight: 700;
                    font-size: 18px;
                    color: #444343;
                  }

                  .descriptions {
                    box-sizing: border-box;
                    padding: 0 10px;
                    margin-top: 10px;
                    font-weight: 400;
                    font-size: 14px;
                    color: #AAAAAA;
                  }

                  .price {
                    margin: 10px 0;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    color: #E62129;

                    .price-text {
                      font-size: 30px;
                    }

                    .price-unit {
                      box-sizing: border-box;
                      padding-left: 10px;
                      position: relative;
                      top: -6px;
                      font-size: 14px;
                    }
                  }

                  .details {
                    font-size: 14px;
                    color: #E62129;

                    .iconfont {
                      margin-left: 3px;
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

</style>
