export default class Cookie {
  constructor(name) {
    this.name = name;
  }
  /**
   * setCookie 设置cookie的函数
   * @param {String} key 设置的cookie的键
   * @param {String} value 设置的cookie的值
   * @param {Number} seconds 设置cookie在多少秒之后失效 - 单位：秒
   * @param {String} path 设置cookie的路径 - 默认是 / 根目录
   */
  setCookie(key,value,seconds,path="/"){
    console.log('Cookie key == ', key);
    console.log('Cookie value == ', value);
    console.log('Cookie seconds == ', seconds);
    console.log('Cookie path == ', path);
    var date = new Date();
    date.setTime(date.getTime()-8*3600*1000+seconds*1000)
    console.log('Cookie date == ', date);
    document.cookie = key + '=' + value + ';expires='+date+";path=" + path;
  }
  /**
  * getCookie 获取cookie的函数
  * @param {String} key 要获取的cookie的键
  * return 返回想要的键对应的值
  */
  getCookie(key){
    var cookies = document.cookie;
    var arr = cookies.split("; ");
    for(var i=0;i<arr.length;i++){
        if(arr[i].split("=")[0] == key){
            return arr[i].split("=")[1];
        }
    }
  }
  /**
  * removeCookie 删除cookie的函数
  * @param {String} key 要删除的cookie的键
  * @param {String} path 要删除的cookie的路径 - 默认为 / 根目录
  */
  removeCookie(key,path="/"){
    this.setCookie(key,null,-1,path);
  }
}
