<template>
  <div class="container">
    <div class="module">
      <div class="main">
        <keep-alive>
          <component :is="page_temp" :sign="sign"></component>
        </keep-alive>
      </div>
    </div>
<!--    <div class="module2" v-if="webBaseInfo">
      <div class="main">
        <div class="copyright" v-if="page_temp == 'footer-default'">
          <p class="technical">本平台仅提供技术支持，保险产品的销售、理赔等服务均由友太安保险经纪有限公司提供保险业务经营许可证：269637000000800</p>
          <p class="provider">
            {{webBaseInfo.security_record.icp}}&nbsp;&nbsp;&nbsp;&nbsp;公安备案号：{{webBaseInfo.security_record.security}}
          </p>
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
  import Footer2 from '@/components/footer/template/Footer2.vue'
  import FooterDefault from '@/components/footer/template/FooterDefault.vue'
  export default {
    // 允许组件模板递归地调用自身
    name: 'Footer',
    // 声明一组可用于组件实例中的组件
    components: {
      'footer-default': FooterDefault,
      'footer-two': Footer2,
    },
    // 一个用于从父组件接收数据的数组或对象
    props: {

    },
    // 该函数返回组件实例的 data 对象
    data() {
      return {
        sign: 1, // 组件标识
        current: 1, // 当前下标
        page_temp: 'footer-default',
        // page_temp: 'footer-two',
        webBaseInfo: null, // 网站基本信息
      }
    },
    // 计算属性：
    computed: {

    },
    // 钩子函数--侦听data变动：
    watch: {
      // 监听路由变化，并同步到导航组件
      $route(to, from) {
        // 计算路径的路由高亮
        this.computedPathRouterFun(to.path);
      }
    },
    // 在实例创建完成后被立即同步调用
    methods: {
      getUrlParam(name) {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
      },

      /**
       * 计算路径的路由高亮
       */
      computedPathRouterFun(str) {
        // console.log('路由高亮 str ==', str)
        let index = 1;

        if (str != '/') {
          let regexp = /^\/.[^/]+/;
          let result = regexp.exec(str);
          if (result.length > 0 && result[0]) {
            if (result[0] == '/insurance') {
              index = 2;
            }
          }
           console.log('路由高亮 result ==', result)
        }
        /*let login_page = this.getUrlParam("page"); //参数名
        if(login_page){
          index = 2;
        }*/
        console.log('当前路由-1',window.location.href);
        // 切换视图面板
        this.switchTabViewFun(index);
      },
      /**
       * 切换视图面板
       */
      switchTabViewFun(index) {
        index = Number(index || 0);
        console.log('视图面板 index == ', index)
        this.current = index;
        this.sign = index;
        switch (index) {
          case 1:
            this.page_temp = 'footer-default';
            break;
          case 2:
            this.page_temp = 'footer-two';
            break;
          default:
            this.page_temp = 'footer-default';
            break
        }
      },
      /**
       * 获取页面数据
       */
      getPageDataFun() {

        // 获取服务信息
        this.getSerivceInfoFun();
      },
      /**
       * 获取服务信息
       */
      getSerivceInfoFun() {
        this.myRequest({
          url: '/api/site/base',
        }).then((res) => {
          console.log('获取服务信息 res == ', res);
          let resData = res.data.data;
          this.webBaseInfo = resData;
          console.log('获取服务信息 this.webBaseInfo == ', this.webBaseInfo);
          // 储存缓存-网站基本信息
          this.mySetStorage({
            name: this.STORAGE.web_base_info,
            value: resData,
            // expires: 2000, // 时间：存储时长，毫秒数
          });
        })
      }
    },
    // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
    created() {

    },
    // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
    mounted() {
      // // 获取页面数据
      this.getPageDataFun();
    },
    beforeCreate() { // 创建前

    },
    beforeMount() { // 挂载前

    },
    beforeUpdate() { // 更新前

    },
    updated() { // 更新后

    },
    beforeDestroy() { // 销毁前

    },
    destroyed() { // 销毁后

    },
    activated() { // 缓存保持，需要由 keep-alive 触发

    },
  }

</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
  .container {
    box-sizing: border-box;
    background: $theme-default-color;
  }

  .module {
    box-sizing: border-box;
    background: $theme-default-color;
  }

  .main {
    box-sizing: border-box;
    padding: 0 $theme-widht-whiteedge;
    // width: $theme-view-widht;
    max-width: $theme-view-widht;
    min-width: $theme-view-min-widht;
    margin: 0 auto;
  }

  .module2 {
    box-sizing: border-box;
    background: #222A34;
  }

  .copyright {
    box-sizing: border-box;
    padding: 30px;
    text-align: center;
    font-size: 14px;
    color: #FFFFFF;

    .technical {}

    .provider {
      margin-top: 20px;
    }
  }

</style>
