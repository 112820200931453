import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import 'lib-flexible'
import './plugins/element.js'
import globalRegexp from './api/global_regexp'
import globalVariable from './api/global_variable'
import globalStorage from './api/global_storage'
import globalFun from './api/global_fun'
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
// import MyDialog from './components/my-dialog/index.js'
import MetaInfo from 'vue-meta-info'
import VueClipboard from 'vue-clipboard2'
import elTableInfiniteScroll from 'el-table-infinite-scroll'
// import vueSocketIo from './socket.io/index'

// 全局组件
import '@/components/index' // 注册全局组件
// 全局组件 end


// Vue.use(vueSocketIo)
Vue.use(elTableInfiniteScroll);
Vue.use(VueClipboard)
Vue.use(MetaInfo)
// Vue.use(MyDialog)
Vue.use(mavonEditor)
// import VueAxios from 'vue-axios'
Vue.prototype.$axios = axios
axios.defaults.baseURL = '/api'
// axios.defaults.baseURL = '/?s=/api'
// axios.defaults.headers.post['Content-Type'] = 'application/json';

Vue.prototype.REGEXP = globalRegexp // 挂载全局表达式
Vue.prototype.GLOBAL = globalVariable // 挂载全局变量
Vue.prototype.STORAGE = globalStorage // 挂载全局缓存名称
Vue.use(globalFun) // 挂载全局函数
Vue.config.productionTip = false





new Vue({
  // sockets: {
  //   //查看socket是否渲染成功
  //   connect() {
  //     console.log("socket 链接成功");
  //   },
  //   //检测socket断开链接
  //   disconnect() {
  //     console.log("socket 断开链接");
  //   },
  //   // 重新链接
  //   reconnect() {
  //     console.log("socket 重新链接");
  //   },
  //   //客户端接收后台传输的socket事件
  //   kline: function (msg) {
  //     console.log(msg);
  //     //然后记性你的一系列操作
  //   },
  // },
  router,
  store,
  render: h => h(App)
}).$mount('#app')
