import Vue from 'vue'
const vue_this = Vue.prototype;

class ShareWindow {
	constructor(url,name) {
		this.name = name;
		this.childUrl = url;
		this.parentWin = window.frameElement;
		this.childWin = null;
	}
	/**
	 * 初始化共享弹窗
	 */
	init() {
		// this.clear();
		console.log('init this.childUrl == ',this.childUrl)
		// this.openWindowlistener(this.name); // 开启窗口监听

    console.log('init this.parentWin == ',this.parentWin )
    if(this.parentWin) {
      console.log('init this.parentWin.contentWindow == ',this.parentWin.contentWindow )
      console.log('init this.parentWin.contentDocument == ',this.parentWin.contentDocument )
    }

    let windowLocation = '';
    try {
      // windowLocation = this.parentWin.location.href;
			// windowLocation = this.parentWin.location.origin;
      // windowLoation = window.parent.origin;
      let iframeWindow = this.parentWin ? (this.parentWin.contentWindow || this.parentWin.contentDocument) : window.parent;
      console.log('init iframeWindow == ',iframeWindow)
      windowLocation = iframeWindow ? iframeWindow.location.origin : '';
    } catch(err) {
			// console.log('init err == ',err)
		}
		console.log('init this.name == ',this.name )
		console.log('init windowLocation == ',windowLocation )
		console.log('init (windowLocation.indexOf(this.name) == -1) == ',(windowLocation.indexOf(this.name) == -1))
		if(windowLocation && windowLocation.indexOf(this.name) == -1 && !this.parentWin) {
			if(this.childUrl) {
					this.creatIframe(this.childUrl).then((res) => { //  创建 iframe 标签
					this.childWin = res;
					this.initData();
				});
			}
		} else {
			this.initData();
		}
	}
	initData() {
		console.log('开启窗口监听 initData == ',this.name)
		this.openWindowlistener(this.name); // 开启窗口监听
		if(this.childWin) {
			this.initParent();
		}
		this.initChild();
	}
	initParent() {
		if(!this.parentWin) return;
		const _this = this;
		// setTimeout(() => {
		//   console.log('init 发送 this.parentWin  共享数据',)
		// 	_this.sandPostMessage(this.parentWin); // 发送共享数据
		// },5000)
	}
	initChild() {
		if(!this.childWin) return;
		const _this = this;
		// setTimeout(() => {
		//   console.log('init 发送 this.childWin  共享数据',)
    //   _this.sandPostMessage(this.childWin); // 发送共享数据
    // },5000)
	}
	/**
	 * 创建 text place 标签
	 */
  creatTextPlace(tag = 'h1',name) {
		name = name || this.textPlaceId;
		let result = document.getElementById(name);
		if(!result) {
			let label = document.createElement(tag);
			label.id = this.textPlaceId;
			document.body.appendChild(label);
			result = document.getElementById(name);
		}
		return result;
	}
	/**
	 * 创建 iframe 标签
	 */
	creatIframe(url) {
		console.log('共享缓存 创建 iframe 标签')
    return new Promise(resolve => {
      let iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.id = 'child-iframfe';
      iframe.src = url;
      document.body.appendChild(iframe)
      let iframeNode = (iframe.contentWindow || iframe.contentDocument);
      resolve(iframeNode);
    })
		return iframeNode;
	}
	/**
	 * 开启窗口监听
	 */
	openWindowlistener(sgin) {
		const _this = this;
		// let place = this.creatTextPlace('p'); // 创建 text place 标签
		let handler = function (e) {  // 监听 message 事件
			e.preventDefault();
			// console.log('messag e == ',e)
			// console.log('messag this == ',this)
      // console.log('message e.path[0].location.href == ',e.path[0].location.href)
			// let val = `${sgin}`
      _this.setLocalStorage(e.data,sgin); // 设置缓存
			// if(place) {
			// 	place.innerHTML = val
			// }
		}
		window.removeEventListener('message', handler, false);
    window.addEventListener('message', handler, false);
	}
	/**
	 * 发送共享数据
	 */
   sandPostMessage(win,data) {
    try {
      if(!win || !win.postMessage) reutrn
      let arr = this.getAllLocalStorage(); // 获取所有缓存
      data = data || JSON.stringify(arr);
      // console.log('发送共享数据 data == ',data)
      // console.log('发送共享数据 name == ',this.name)
      win.postMessage(data, '*');
    } catch(err) {

    }
	}
  /**
   * 获取所有缓存
   */
  getAllLocalStorage() {
    // let len = localStorage.length;  // 获取长度
    // let arr = new Array(); // 定义数据集
    // for(let i = 0; i < len; i++) {
    //   // 获取key 索引从0开始
    //   let getKey = localStorage.key(i);
    //   // 获取key对应的值
    //   let getVal = localStorage.getItem(getKey);
    //   // 放进数组
    //   arr[i] = {
    //     'key': getKey,
    //     'val': getVal,
    //   }
    // }
    let { login_token, login_info } = vue_this.STORAGE;
    let arr = [];
    let login_token_value = vue_this.myGetStorage(login_token);
    let login_info_value = vue_this.myGetStorage(login_info);
    arr.push({
      'key': login_token,
      'val': login_token_value,
    },{
      'key': login_info,
      'val': login_info_value,
    })
    return arr;
  }
  /**
   * 设置缓存
   */
  setLocalStorage(data,name) {
		// console.log('设置缓存 data == ',data)
		console.log('设置缓存 typeof data == ',typeof data == 'string')
    if(typeof data == 'string') {
      data = JSON.parse(data);
    }
    if(!Array.isArray(data)) return;
    data.forEach((item,ind) => {
      let { key, val } = item;
      // console.log('设置缓存 item == ',item)
      // 储存缓存-登录状态
      this.setItem({
        name: key,
        value: val,
      });
    })

    // 【测试】储存缓存-登录状态
    // this.setItem({
    //   name: 'test-cache',
    //   value: `test-cache——${name}——${String(Date.now()).slice(-4)}`,
    // });
  }
  //设置缓存
  setItem(params){
    let obj = {
      name:'',
      value:'',
      expires:"",
      startTime: new Date().getTime()//记录何时将值存入缓存，毫秒级
    }
    let options = {};
    //将obj和传进来的params合并
    Object.assign(options,obj,params);
    if(options.expires){
      //如果options.expires设置了的话
      //以options.name为key，options为值放进去
      localStorage.setItem(options.name,JSON.stringify(options));
    }else{
      //如果options.expires没有设置，就判断一下value的类型
      let type = Object.prototype.toString.call(options.value);
      //如果value是对象或者数组对象的类型，就先用JSON.stringify转一下，再存进去
      if(type == '[object Object]'){
        options.value = JSON.stringify(options.value);
      }
      if(type == '[object Array]'){
        options.value = JSON.stringify(options.value);
      }
      localStorage.setItem(options.name,options.value);
    }
  }
	//移出全部缓存
  clear(){
    localStorage.clear();
  }
}

export default ShareWindow
