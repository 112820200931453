export default class Session {
  constructor(name){
    this.name = name || 'session';
  }
  setSession(key, val) {
    if (typeof val !== 'string') {
      val = JSON.stringify(val);
    }
    window.sessionStorage.setItem(key, val);
  }
  getSession(key) {
    let val = window.sessionStorage.getItem(key);
    try {
      val = JSON.parse(val);
    } catch (e) {
      console.log(`${val} Unexpected token H in JSON at position 0`);
    }
    return val;
  }
  clearSession() {
      window.sessionStorage.clear();
  }
  removeSession(key) {
    if (window.sessionStorage.getItem(key)) {
      window.sessionStorage.removeItem(key);
    }
  }
};
