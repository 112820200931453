<template>
  <div class="service-container">
    <div class="serivce-module" :class="!is_show_service ? 'hide' : ''">
      <ul class="serivce-list">
        <li class="sl-item phone-consult">
          <div class="sl-link">
            <div class="sl-icon">
              <div class="icon-box rest">
                <div class="pc-main">
                  <p class="pc-text">咨询电话（9:00-21:00）</p>
                  <p class="pc-text phone" v-show="webBaseInfo.service">{{webBaseInfo.service.tel}}</p>
                </div>
              </div>
              <div class="icon-box place">
                <i class="iconfont icon-dianhua"></i>
              </div>
            </div>
            <p class="sl-name">电话咨询</p>
          </div>
        </li>
        <li class="sl-item contact-service" v-if="clientSource !== 'baiduboxapp'">
          <div class="sl-link">
            <div class="sl-icon">
              <div class="icon-box rest">
                <div class="cs-main" v-show="webBaseInfo.service">
                  <img class="fake-img" :src="webBaseInfo.service.service_wx_qr" alt="联系客服二维码">
                </div>
              </div>
              <div class="icon-box place">
                <i class="iconfont icon-kefu"></i>
              </div>
            </div>
            <p class="sl-name">联系客服</p>
          </div>
        </li>
        <li class="sl-item claims-specialist" v-if="clientSource !== 'baiduboxapp'">
          <div class="sl-link">
            <div class="sl-icon">
              <div class="icon-box rest">
                <div class="cs-main" v-show="webBaseInfo.service">
                  <div class="cs-content">
                    <img class="fake-img" :src="webBaseInfo.service.service_claims_wx_qr" alt="理赔专员二维码">
                  </div>
                  <div class="cs-footer">
                    <p class="cs-title">微信</p>
                      <div class="cs-descriptions">
                        <div class="cd-key">电话：</div>
                        <div class="cd-val">
                          <p class="fake-p">{{webBaseInfo.service.service_claims_tel}}</p>
                          <p class="fake-p">{{webBaseInfo.service.service_claims_mobile}}</p>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
              <div class="icon-box place">
                <i class="iconfont icon-_baoxianlipei"></i>
              </div>
            </div>
            <p class="sl-name">理赔专员</p>
          </div>
        </li>
      </ul>
      <div class="sl-toggle" @click="is_show_service = !is_show_service">
        <i class="iconfont icon-arrow-right-1-icon" v-show="is_show_service"></i>
        <i class="iconfont icon-arrow-left-1-icon" v-show="!is_show_service"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 允许组件模板递归地调用自身
  name: '',
  // 声明一组可用于组件实例中的组件
  components: {

  },
  // 一个用于从父组件接收数据的数组或对象
  props: {

  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {
      clientSource: this.GLOBAL.clientSource,
      is_show_service: true,
      webBaseInfo: { // 网站基本信息
        service: {}
      },
    }
  },
  // 计算属性：
  computed: {

  },
  // 钩子函数--侦听data变动：
  watch: {

  },
  // 在实例创建完成后被立即同步调用
  methods: {
    /**
     * 获取页面数据
     */
    getPageDataFun() {
      // 获取服务信息
      this.getSerivceInfoFun();
    },
    /**
     * 获取服务信息
     */
    getSerivceInfoFun() {
      // 储存缓存-网站基本信息
      let webBaseInfo = this.myGetStorage(this.STORAGE.web_base_info);
      console.log('webBaseInfo == ',webBaseInfo)
      if( webBaseInfo ) {
        let { service } = webBaseInfo;
        let tel_left = service.tel.slice(0,3);
        let tel_center = service.tel.slice(3,7);
        let tel_right = service.tel.slice(7,11);
        let new_tel = `${tel_left}  ${tel_center}  ${tel_right}`;
        webBaseInfo.service.tel = new_tel;
        this.webBaseInfo = webBaseInfo;
      }
      console.log('this.webBaseInfo == ',this.webBaseInfo)
    }
  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {

  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {
    // 获取页面数据
    this.getPageDataFun();
  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前

  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
.service-container {
  box-sizing: border-box;
}

.serivce-module {
  $box-width: 76px;
  $circle: 50px;
  z-index: 50;
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: #fff;
  box-shadow: -4px 0 10px -1px rgba(0,0,0,.1);
  transition: all .4s;
  &.hide {
    right: -$box-width;
  }
  .serivce-list {
    z-index: 2;
    position: relative;
    background: #fff;
    .sl-item {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
        height: 1px;
        background: #E6E6E6;
      }
      &:first-of-type {
        &::before {
          display: none;
        }
      }
      .sl-link {
        box-sizing: border-box;
        padding: 20px 10px;
        .sl-icon {
          margin:  0 auto;
          position: relative;
          width: $circle;
          height: $circle;
          .icon-box {
            width: $circle;
            height: $circle;
            border-radius: $circle;
            overflow: hidden;
            color: #fff;
            background: #1DCD65;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            &.place {
              z-index: 2;
              line-height: $circle;
              text-align: center;
            }
            &.rest {
              z-index: 1;
            }
            .iconfont {
              font-size: 14px;
            }
          }
        }
        .sl-name {
          margin-top: 10px;
          text-align: center;
          font-size: 14px;
          color: #444343;
        }
      }
      &.phone-consult {
        .sl-link {
          .sl-icon {
            &:hover {
              .icon-box.rest {
                width: 242px;
              }
            }
            .icon-box {
              background: #FF9A59;
              &.place {
                .iconfont {
                  font-size: 28px;
                }
              }
              &.rest {
                transition: all .4s;
                overflow: hidden;
                .pc-main {
                  box-sizing: border-box;
                  padding: 5px 0 0 22px;
                  .pc-text {
                    font-size: 14px;
                    color: #FFFFFF;
                    white-space: nowrap;
                    &.phone {
                      box-sizing: border-box;
                      padding-left: 17px;
                      margin-top: 4px;
                      font-size: 16px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.contact-service {
        .sl-link {
          .sl-icon {
            &:hover {
              .icon-box.rest {
                width: 185px;
                opacity: 1;
                overflow: initial;
              }
            }
            .icon-box {
              background: #1DCD65;
              &.place {
                .iconfont {
                  font-size: 34px;
                }
              }
              &.rest {
                transition: all .4s;
                background: none;
                opacity: 0;
                .cs-main {
                  position: relative;
                  top: -18px;
                  width: 115px;
                  height: 115px;
                  box-shadow: 0 0 14px -7px rgba(0,0,0,.4);
                  .fake-img {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }
          }
        }
      }
      &.claims-specialist {
        .sl-link {
          .sl-icon {
            &:hover {
              .icon-box.rest {
                width: 206px;
                opacity: 1;
                overflow: initial;
              }
            }
            .icon-box {
              background: #57C9FE;
              &.place {
                .iconfont {
                  font-size: 28px;
                }
              }
              &.rest {
                transition: all .4s;
                background: none;
                opacity: 0;
                .cs-main {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  width: 140px;
                  // height: 140px;
                  position: relative;
                  top: -48px;
                  box-shadow: 0 0 14px -7px rgba(0,0,0,.4);
                  background: #fff;
                  .cs-content {
                    flex: 1;
                    min-height: 0;
                    width: 100px;
                    height: 100px;
                    .fake-img {
                      width: 100%;
                      height: 100%;
                    }
                  }
                  .cs-footer {
                    width: 100%;
                    text-align: center;
                    font-size: 12px;
                    color: #444343;
                    // .cs-title {
                    //   box-sizing: border-box;
                    //   padding: 4px;
                    // }
                    .cs-descriptions {
                      display: flex;
                      width: 100%;
                      box-sizing: border-box;
                      padding: 5px 8px;
                      margin-top: 6px;
                      box-shadow: 0 -2px 3px -4px rgba(0,0,0,.6);
                      .cd-val {
                        text-align: left;
                        .fake-p {
                          margin-top: 4px;
                          &:first-of-type {
                            margin-top: 2px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .sl-toggle {
    $size: 30px;
    width: $size;
    height: $size;
    line-height: $size;
    border-radius: 50%;
    background: #fff;
    box-shadow: -4px 0 10px -1px rgba(0,0,0,.1);
    text-align: center;
    font-size: 18px;
    color: #808080;
    z-index: 1;
    position: absolute;
    top: 50%;
    left: calc(-#{$size} + 10px);
    transform: translateY(-50%);
  }
}
</style>
