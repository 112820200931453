<template>
  <div class="place" v-if="webBaseInfo">
    <div class="info">
      <h3 class="title">联系我们</h3>
      <p class="info-item">公司地址：{{webBaseInfo.company.address}}</p>
      <p class="info-item">电子邮箱：{{webBaseInfo.company.email}}</p>
      <p class="info-item">咨询电话：{{webBaseInfo.company.tel}}</p>
    </div>
    <div class="appley" v-if="clientSource !== 'baiduboxapp'">
      <div class="description">
        <p class="text">扫一扫进入小程序了解更多</p>
        <i class="iconfont icon-arrowRight"></i>
      </div>
      <div class="qr-code">
        <el-image :src="ossUrl + '/images/logo-img/applet-qrcode.png'" fit="scale-down"
          :preview-src-list="[ossUrl + '/images/logo-img/applet-qrcode.png']"></el-image>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    // 允许组件模板递归地调用自身
    name: '',
    // 声明一组可用于组件实例中的组件
    components: {

    },
    // 一个用于从父组件接收数据的数组或对象
    props: {

    },
    // 该函数返回组件实例的 data 对象
    data() {
      return {
        clientSource: this.GLOBAL.clientSource,
        ossUrl: this.GLOBAL.ossUrl,
        webBaseInfo: null, // 网站基本信息
      }
    },
    // 计算属性：
    computed: {

    },
    // 钩子函数--侦听data变动：
    watch: {

    },
    // 在实例创建完成后被立即同步调用
    methods: {
      /**
       * 获取页面数据
       */
      getPageDataFun() {
        // 获取服务信息
        this.getSerivceInfoFun();
      },
      /**
       * 获取服务信息
       */
      getSerivceInfoFun() {
        // 储存缓存-网站基本信息
        let webBaseInfo = this.myGetStorage(this.STORAGE.web_base_info);
        console.log('webBaseInfo == ', webBaseInfo)
        if (webBaseInfo) {
          let {
            service
          } = webBaseInfo;
          let tel_left = service.tel.slice(0, 3);
          let tel_center = service.tel.slice(3, 7);
          let tel_right = service.tel.slice(7, 11);
          let new_tel = `${tel_left}  ${tel_center}  ${tel_right}`;
          webBaseInfo.service.tel = new_tel;
          this.webBaseInfo = webBaseInfo;
        }
        console.log('this.webBaseInfo == ', this.webBaseInfo)
      }
    },
    // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
    created() {

    },
    // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
    mounted() {
      // 获取页面数据
      this.getPageDataFun();
    },
    beforeCreate() { // 创建前

    },
    beforeMount() { // 挂载前

    },
    beforeUpdate() { // 更新前

    },
    updated() { // 更新后

    },
    beforeDestroy() { // 销毁前

    },
    destroyed() { // 销毁后

    },
    activated() { // 缓存保持，需要由 keep-alive 触发

    },
  }

</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
  .container {
    box-sizing: border-box;
  }

  .place {
    padding: 50px 220px;
    display: flex;

    .info {
      flex: 1;
      min-width: 0;
      box-sizing: border-box;
      color: #fff;

      .title {
        font-size: 18px;
      }

      .info-item {
        margin-top: 10px;
        font-size: 14px;
      }
    }

    .appley {
      display: flex;
      align-items: center;

      .description {
        padding: 0 20px;
        text-align: center;
        font-size: 14px;
        color: #fff;

        .text {
          box-sizing: border-box;
          padding-bottom: 10px;
        }

        .iconfont {
          font-size: 32px;
        }
      }

      .qr-code {
        width: 120px;
        height: 120px;
        // background: $theme-photo-background-color;
        // border-radius: 4px;
        overflow: hidden;
      }
    }
  }

</style>
