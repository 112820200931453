<template>
  <div class="place">
    <div class="block">
      <div class="consult">
        <div class="icon">
          <i class="iconfont icon-shouye_hzixunrexian"></i>
        </div>
        <p class="descriptions" v-show="webBaseInfo.service">7X24小时理赔协助咨询电话：{{webBaseInfo.service.service_claims_tel}}&nbsp;&nbsp;&nbsp;&nbsp;{{webBaseInfo.service.service_claims_mobile}}</p>
      </div>
      <div class="senior-licence" v-if="clientSource !== 'baiduboxapp'">
        <div class="sl-key">资深牌照：</div>
        <div class="sl-val">
          <ul class="sl-list">
            <li class="sl-item">
              <a class="sl-link" :href="ossUrl + '/images/insurance-base/公司资质(1).pdf'" target="_blank">公司资质</a>
            </li>
             <li class="sl-item">
              <a class="sl-link" :href="ossUrl + '/images/insurance-base/信息披露(1).pdf'" target="_blank">信息披露</a>
            </li>
             <li class="sl-item">
              <a class="sl-link" :href="ossUrl + '/images/insurance-base/隐私声明(1).pdf'" target="_blank">隐私声明</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="copyright">
        <p class="technical">保险业务经营许可证：269637000000800&nbsp;&nbsp;&nbsp;&nbsp;粤ICP备17002227号</p>
        <p class="provider">版权所有©2017友太安保险经纪有限公司</p>
      </div>
    </div>
<!--    <div class="block">
      <div class="wechat-scan" v-if="clientSource !== 'baiduboxapp'">
        <div class="qr-code">
          <el-image
            :src="ossUrl + '/images/logo-img/applet-qrcode.jpg'"
            fit="scale-down"
            :preview-src-list="[ossUrl + '/images/logo-img/applet-qrcode.jpg']"
          ></el-image>
        </div>
        <p class="descriptions">小程序投保更快捷</p>
      </div>
    </div>-->

    <!-- 应用于保险的服务菜单 -->
    <service-menu />
  </div>
</template>
<script>
import ServiceMenu from '@/components/service-menu/ServiceMenu.vue'
export default {
  // 允许组件模板递归地调用自身
  name: '',
  // 声明一组可用于组件实例中的组件
  components: {
    ServiceMenu
  },
  // 一个用于从父组件接收数据的数组或对象
  props: {

  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {
      clientSource: this.GLOBAL.clientSource,
      ossUrl: this.GLOBAL.ossUrl,
      is_show_service: true,
      webBaseInfo: { // 网站基本信息
        service: {}
      },
    }
  },
  // 计算属性：
  computed: {

  },
  // 钩子函数--侦听data变动：
  watch: {

  },
  // 在实例创建完成后被立即同步调用
  methods: {
    /**
     * 获取页面数据
     */
    getPageDataFun() {
      // 获取服务信息
      this.getSerivceInfoFun();
    },
    /**
     * 获取服务信息
     */
    getSerivceInfoFun() {
      // 储存缓存-网站基本信息
      let webBaseInfo = this.myGetStorage(this.STORAGE.web_base_info);
      console.log('webBaseInfo == ',webBaseInfo)
      if( webBaseInfo ) {
        let { service } = webBaseInfo;
        let tel_left = service.tel.slice(0,3);
        let tel_center = service.tel.slice(3,7);
        let tel_right = service.tel.slice(7,11);
        let new_tel = `${tel_left}  ${tel_center}  ${tel_right}`;
        webBaseInfo.service.tel = new_tel;
        this.webBaseInfo = webBaseInfo;
      }
      console.log('this.webBaseInfo == ',this.webBaseInfo)
    }
  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {

  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {
    // 获取页面数据
    this.getPageDataFun();
  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前

  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
.container {
  box-sizing: border-box;
}

.place {
  padding: 50px 0;
  display: flex;
  .block {
    position: relative;
    box-sizing: border-box;
    padding: 10px 190px;
    // padding: 10px;
    &::before {
      content: '';
      display: inline-block;
      width: 1px;
      height: 100%;
      background: #fff;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
    &:first-of-type {
      &::before {
        display: none;
      }
    }
    &:nth-child(2) {
      flex: 1;
      min-width: 0;
    }
    .consult {
      display: flex;
      align-items: center;
      color: #fff;
      .icon {
        margin-right: 8px;
        width: 24px;
        height: 24px;
        line-height: 24px;
        .iconfont {
          font-size: 24px;
        }
      }
      .descriptions {
        font-size: 16px;
      }
    }
    .column {
      display: flex;
      justify-content: space-around;
      .column-item {
        color: #fff;
        .column-title {
          margin-bottom: 25px;
          font-weight: 400;
          font-size: 18px;
        }
        .column-list {
          .cl-item {
            margin-top: 15px;
            &:first-of-type {
              margin-top: 0;
            }
          }
          .cl-link {
            font-size: 14px;
            color: #f5f5f5;
          }
        }
      }
    }
    .wechat-scan {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .descriptions {
        text-align: center;
        font-size: 14px;
        color: #fff;
        .text {
          box-sizing: border-box;
          padding-bottom: 10px;
        }
        .iconfont {
          font-size: 32px;
        }
      }
      .qr-code {
        margin-bottom: 21px;
        width: 120px;
        height: 120px;
        background: $theme-photo-background-color;
        border-radius: 4px;
        overflow: hidden;
      }
    }
  }
}

.senior-licence {
  display: flex;
  align-items: center;
  margin-top: 24px;
  .sl-key {
    font-size: 18px;
    color: #FFFFFF;
  }
  .sl-val {
    .sl-list {
      display: flex;
      .sl-item {
        margin-left: 32px;
        .sl-link {
          text-decoration: underline;
          font-size: 16px;
          color: #F5F5F5;
          cursor: pointer;
        }
      }
    }
  }
}

.copyright {
  margin-top: 50px;
  font-size: 14px;
  color: #FFFFFF;
  .technical {}
  .provider {
    margin-top: 20px;
  }
}
</style>
