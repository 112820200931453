export default class Checktool {
  constructor(name) {
    this.name = name;
  }
  /**
   * 校验-文本-名称(不包含标点符号与数字)
   * @param {String} str
   */
  checkTextNameNotRestChars(str) {
    str = str || '';
    // console.log('校验-文本-名称 str == ',str)
    if((/\d+/).test(str)){
      return false;
    }else{
      // return ((/^[\u4e00-\u9fa5]{0,}$/).test(str));
      let str_letter = /^[a-zA-Z\u4e00-\u9fa5]+$/;
      return (str_letter.test(str));
    }
  }
  // js正则表达式护照校验代码
  checkPassport(code){
    if(!code || !/^1[45][0-9]{7}$|([P|p|S|s]\d{7}$)|([S|s|G|g]\d{8}$)|([Gg|Tt|Ss|Ll|Qq|Dd|Aa|Ff]\d{8}$)|([H|h|M|m]\d{8,10})$/.test(code)){
      return false;
    }
    return true;
  }
  // js正则表达式手机号校验(包含166.199等)
  isPoneAvailable(str) {
    var myreg=/^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57]|19[0-9]|16[0-9])[0-9]{8}$/;
    if (!myreg.test(str)) {
      return false;
    } else {
      return true;
    }
  }
  // js正则表达式统一社会信用码校验
  checkSocialCreditCode(Code) {
    var patrn = /^[0-9A-Z]+$/;
    //18位校验及大写校验
    if ((Code.length != 18) || (patrn.test(Code) == false)) {
      console.info("不是有效的统一社会信用编码！");
      return false;
    } else {
      var Ancode;//统一社会信用代码的每一个值
      var Ancodevalue;//统一社会信用代码每一个值的权重
      var total = 0;
      var weightedfactors = [1, 3, 9, 27, 19, 26, 16, 17, 20, 29, 25, 13, 8, 24, 10, 30, 28];//加权因子
      var str = '0123456789ABCDEFGHJKLMNPQRTUWXY';
      //不用I、O、S、V、Z
      for (var i = 0; i < Code.length - 1; i++) {
        Ancode = Code.substring(i, i + 1);
        Ancodevalue = str.indexOf(Ancode);
        total = total + Ancodevalue * weightedfactors[i];
        //权重与加权因子相乘之和
      }
      var logiccheckcode = 31 - total % 31;
      if (logiccheckcode == 31) {
        logiccheckcode = 0;
      }
      var Str = "0,1,2,3,4,5,6,7,8,9,A,B,C,D,E,F,G,H,J,K,L,M,N,P,Q,R,T,U,W,X,Y";
      var Array_Str = Str.split(',');
      logiccheckcode = Array_Str[logiccheckcode];


      var checkcode = Code.substring(17, 18);
      if (logiccheckcode != checkcode) {
        console.info("不是有效的统一社会信用编码！");
        return false;
      }else{
        return true;
      }
      return true;
    }
  }
  // 身份证校验
  identityCodeValid(str) {
    var myreg=/(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9Xx]$)/;
    if (str == "111111111111111" || !myreg.test(str)) {
        return false;
    } else {
        return true;
    }
  }
  /**
   * 身份证校验2
   * @param {String} idcard
   * @returns
   */
  identityCodeValid2(idcard) {
    // console.log('PHP idcard == ',idcard)
    // 只能是18位
    if (idcard.length != 18) {
      return false;
    }

    // 取出本体码
    var idcard_base = idcard.slice(0, 17);
    // console.log('PHP idcard_base == ',idcard_base)

    // 取出校验码
    var verify_code = idcard.slice(17);
    // console.log('PHP verify_code == ',verify_code)

    // 加权因子
    var factor = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);

    // 校验码对应值
    var verify_code_list = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');

    // 根据前17位计算校验码
    var total = 0;
    for (var i = 0; i < 17; i++) {
      total += idcard_base.slice(i, i + 1) * factor[i];
    }

    // 取模
    var mod = total % 11;
    // console.log('PHP total == ',total)
    // console.log('PHP mod == ',mod)
    // console.log('PHP verify_code == verify_code_list[mod] == ',verify_code == verify_code_list[mod])

    // 比较校验码
    if (verify_code == verify_code_list[mod]) {
      return true;
    } else {
      return false;
    }
  }
  // 银行卡号码检测
  luhnCheck(bankno) {
    if (bankno == "" || bankno.length < 16 || bankno.length > 19) {
      return false;
    }
    var lastNum = bankno.substr(bankno.length - 1, 1); //取出最后一位（与luhn进行比较）
    var first15Num = bankno.substr(0, bankno.length - 1); //前15或18位
    var newArr = new Array();
    for (var i = first15Num.length - 1; i > -1; i--) { //前15或18位倒序存进数组
      newArr.push(first15Num.substr(i, 1));
    }
    var arrJiShu = new Array(); //奇数位*2的积 <9
    var arrJiShu2 = new Array(); //奇数位*2的积 >9
    var arrOuShu = new Array(); //偶数位数组
    for (var j = 0; j < newArr.length; j++) {
      if ((j + 1) % 2 == 1) { //奇数位
        if (parseInt(newArr[j]) * 2 < 9) arrJiShu.push(parseInt(newArr[j]) * 2);
        else arrJiShu2.push(parseInt(newArr[j]) * 2);
      } else //偶数位
        arrOuShu.push(newArr[j]);
    }
    var jishu_child1 = new Array(); //奇数位*2 >9 的分割之后的数组个位数
    var jishu_child2 = new Array(); //奇数位*2 >9 的分割之后的数组十位数
    for (var h = 0; h < arrJiShu2.length; h++) {
      jishu_child1.push(parseInt(arrJiShu2[h]) % 10);
      jishu_child2.push(parseInt(arrJiShu2[h]) / 10);
    }
    var sumJiShu = 0; //奇数位*2 < 9 的数组之和
    var sumOuShu = 0; //偶数位数组之和
    var sumJiShuChild1 = 0; //奇数位*2 >9 的分割之后的数组个位数之和
    var sumJiShuChild2 = 0; //奇数位*2 >9 的分割之后的数组十位数之和
    var sumTotal = 0;
    for (var m = 0; m < arrJiShu.length; m++) {
      sumJiShu = sumJiShu + parseInt(arrJiShu[m]);
    }
    for (var n = 0; n < arrOuShu.length; n++) {
      sumOuShu = sumOuShu + parseInt(arrOuShu[n]);
    }
    for (var p = 0; p < jishu_child1.length; p++) {
      sumJiShuChild1 = sumJiShuChild1 + parseInt(jishu_child1[p]);
      sumJiShuChild2 = sumJiShuChild2 + parseInt(jishu_child2[p]);
    }
    //计算总和
    sumTotal = parseInt(sumJiShu) + parseInt(sumOuShu) + parseInt(sumJiShuChild1) + parseInt(sumJiShuChild2);
    //计算luhn值
    var k = parseInt(sumTotal) % 10 == 0 ? 10 : parseInt(sumTotal) % 10;
    var luhn = 10 - k;
    if (lastNum == luhn) {
      return true;
    } else {
      return false;
    }
  }
  // 传真
  checkUserFax(str) {
    var reg=/^(\d{3,4}-)?\d{7,8}$/;
    if (str){
      if(reg.test(str)==false){
        return false;
      }
    }
    return true;
  }
}
