const STATUS = 1; // 1/正式版 2/测试版

// 客房端来源【web：网页，baiduboxapp百度小程序】
var clientSource = 'web';
var u = navigator.userAgent;
/*var isbaidu = u.indexOf('baiduboxapp') > -1 ; //百度小程序
if(isbaidu){
  clientSource = 'baiduboxapp'
}*/

// 全局变量表
let variable_form = {};

// 开发版本 （测试版）
let develop_data = {
   // baseUrl: 'www.facebook.com',
   baseUrl: 'https://test.baituanle.com/?s=',
   ossBaseUrl: 'https://bdt-file.oss-cn-beijing.aliyuncs.com',
   ossUrl: 'https://bdt-file.oss-cn-beijing.aliyuncs.com/bands-team',
   applet_appid: 'wxf05197e3fb501e3f',
   token: '123456',
   mainDomainNameInfo: {
    aUrl: {
      name: 'btl.baituanle.com',
      url: 'https://btl.baituanle.com',
    }
  },
  sldInfo: { // 二级域名
    aUrl: {
      name: 'lyybao.baituanle.com',
      url: 'https://lyybao.baituanle.com',
    }
  },
   insurance: {
    requestConfig: {
      url: 'http://www.lyybao.com/ins/business/rest/custorder',
      standbyUrl: '//www.lyybao.com/ins/business/rest/custorder'
    },
    accountConfig: {
      account: 'API测试账户',
      passwrod: 'aaa@123',
    }
   },
  clientSource:clientSource,
}

// 线上版本 （正式版）
let online_data = {
  baseUrl: 'https://www.baituanle.com/?s=',
  ossBaseUrl: 'https://bdt-file.oss-cn-beijing.aliyuncs.com',
  ossUrl: 'https://bdt-file.oss-cn-beijing.aliyuncs.com/bands-team',
  applet_appid: 'wxf05197e3fb501e3f',
  token: '123456',
  mainDomainNameInfo: {
    aUrl: {
      name: 'www.baituanle.com',
      url: 'https://www.baituanle.com',
    }
  },
  sldInfo: { // 二级域名
    aUrl: {
      name: 'btl.lyybao.cn',
      url: 'https://btl.lyybao.cn',
    }
  },
  insurance: {
    requestConfig: {
      url: 'http://www.lyybao.cn/ins/business/rest/custorder',
      standbyUrl: '//www.lyybao.cn/ins/business/rest/custorder'
    },
    accountConfig: {
      account: '18194856789',
      passwrod: 'Btl18194856789',
    }
  },
  clientSource:clientSource,
}

if(STATUS == 1) { // 正式版
  variable_form = online_data;
} else { // 测试版
  variable_form = develop_data;
}


export default variable_form
