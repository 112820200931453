// 全局正则表达式表
const regexp_form = {
  is_default_request: /^\\/g, // 是否是数字
  is_number: /^[0-9]*$/, // 是否是数字
  is_phone: /^(((13[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[3-8]{1})|(18[0-9]{1})|(19[0-9]{1})|(14[5-7]{1}))+\d{8})$/, // 电话号码正则表达式
  // is_floating: /^(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*))$/, // 浮点数-小数点后两位表达式
  is_floating: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,// 浮点数-小数点后两位表达式
  is_idcard: /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/, // 身份证表达式
  is_email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // 邮箱正则表达式
  is_social_credit : /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/, // 统一社会信用代码正则表达式
  // is_passport: /^[0-9a-zA-Z]+$/, // 护照表达式
  is_passport: /^([a-zA-z]|[0-9]){5,17}$/, // 护照表达式

}

export default regexp_form
