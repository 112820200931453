<template>
  <div class="login-temp-container">
    <div class="yet-login" v-if="is_login_status">
      <div class="user-info">
        <div class="user-headimg">
          <img class="fake-img" :src="login_info.avatar" alt="" />
        </div>
        <el-tooltip effect="dark" content="" placement="right" v-if="login_info.nickname_limit">
          <div slot="content">
            <p>{{login_info.nickname}}</p>
          </div>
          <h3 class="user-name">{{login_info.nickname_limit}}</h3>
        </el-tooltip>
        <h3 class="user-name" v-else>{{login_info.nickname}}</h3>
      </div>
      <div class="float-palce">
        <ul class="control-list">
          <li class="cl-item">
            <router-link class="cl-link" :to="{path: '/login/5'}">修改密码</router-link>
          </li>
          <li class="cl-item log-out" @click="logOutClickFun">
            <div class="cl-link">退出登录</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="un-login" v-else>
      <router-link :to="'/login?id=1'">
        <el-button class="fake-btn" size="mini">登录</el-button>
      </router-link>
    </div>
  </div>
</template>

<script>
  import {
    mapState,
    mapMutations
  } from 'vuex';
  export default {
    // 允许组件模板递归地调用自身
    name: 'LoginTemp',
    // 声明一组可用于组件实例中的组件
    components: {

    },
    // 一个用于从父组件接收数据的数组或对象
    props: {

    },
    // 该函数返回组件实例的 data 对象
    data() {
      return {
        ossUrl: this.GLOBAL.ossUrl,
        name_limit_number: 10, // 限制名称长度 10 + 3(...) = 13个字符
        login_info: {}, // 登录信息
      }
    },
    // 计算属性：
    computed: {
      ...mapState(['is_login_status']),
    },
    // 钩子函数--侦听data变动：
    watch: {
      is_login_status: function (e) {
        console.log('登录状态 is_login_status e == ', e)
        if (e) {
          // 获取登录信息
          this.getLoginInfoFun();
        }
      }
    },
    // 在实例创建完成后被立即同步调用
    methods: {
      ...mapMutations(['isLoginStatusChange']),
      /**
       * 获取用户信息
       */
      getUsetInfoFun(user) {
        let {
          name_limit_number
        } = this;
        let {
          nickname
        } = user;
        if (nickname && nickname.length > name_limit_number) {
          user.nickname_limit = nickname.slice(0, name_limit_number) + '...';
        }
        this.login_info = user;
        console.log('缓存-登录信息', this.login_info)
      },
      /**
       * 获取登录信息
       */
      getLoginInfoFun() {
        // Cookie 获取缓存-登录状态
        // let login_token = this.myGetCookie(this.STORAGE.login_token);

        // 登录缓存-状态
        let login_token = this.myGetStorage(this.STORAGE.login_token);
        console.log('获取登录信息 缓存-登录信息 login_token == ', login_token)

        // 登录缓存-信息
        let login_info = this.myGetStorage(this.STORAGE.login_info);

        // console.log('Cookie 登录缓存-状态 login_token == ',login_token)
        console.log('缓存-登录信息 login_info == ', login_info)

        if (!(String(login_token) == 'null' || typeof login_token == 'undefined') && login_info) {
          console.log('获取登录信息 缓存-登录信息 if login_token == ', login_token)
          // this.login_info = login_info;
          // 获取用户信息
          this.getUsetInfoFun(login_info);
        } else {
          // 登录信息请求
          this.myLoginInfoRequest().then((res) => {
            let resData = res.data.data;
            // this.login_info = resData;
            // 获取用户信息
            this.getUsetInfoFun(resData);
            this.isLoginStatusChange(true);
            // console.log('缓存-登录信息',this.login_info)
          });
        }
      },
      /**
       * 退出登录按钮
       */
      logOutClickFun() {
        // 退出登录请求
        this.logOutRequestFun();
      },
      /**
       * 退出登录请求
       */
      logOutRequestFun() {
        // this.myRequest({
        //   url: '/api/login/logout',
        // }).then((res) => {
        //   console.log('退出登录 res == ',res);

        //   // Cookie 删除息缓存-登录状态
        //   this.myRemoveCookie(this.STORAGE.login_token);

        //   // // 删除息缓存-登录状态
        //   this.myRemoveStorage(this.STORAGE.login_token);

        //   // 删除缓存-登录信息
        //   this.myRemoveStorage(this.STORAGE.login_info);

        //   // 储存缓存-登录状态
        //   this.mySetStorage({
        //     name: this.STORAGE.login_token,
        //     value: false,
        //     // expires: 1000 * 60 * 60 * 24, // 时间：存储时长，毫秒数
        //   });

        //   // 储存缓存-登录状态
        //   this.mySetStorage({
        //     name: this.STORAGE.login_info,
        //     value: {},
        //     // expires: 1000 * 60 * 60 * 24, // 时间：存储时长，毫秒数
        //   });

        //   // vuex 改变登录状态
        //   this.isLoginStatusChange(false);

        //   this.login_info = '';

        //   // 刷新页面
        //   this.refreshPageFun();

        // }).catch((err) => {
        //   console.log('退出登录 err == ',err)
        // })

        // 全局-退出登录请求
        this.mylogOutRequestFun().then((res) => {
          //  vuex 改变登录状态
          this.isLoginStatusChange(false);

          this.login_info = '';

          // 刷新页面
          this.refreshPageFun();
        });
      },
      /**
       * 刷新页面
       */
      refreshPageFun() {
        let route = this.$route;
        console.log('刷新页面 route == ', route);
        // this.$router.replace(route.path);
        this.$router.replace('/');
        this.$router.go(0);
      }
    },
    // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
    created() {

    },
    // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
    mounted() {
      // 获取登录信息
      this.getLoginInfoFun();
    },
    beforeCreate() { // 创建前

    },
    beforeMount() { // 挂载前

    },
    beforeUpdate() { // 更新前

    },
    updated() { // 更新后

    },
    beforeDestroy() { // 销毁前

    },
    destroyed() { // 销毁后

    },
    activated() { // 缓存保持，需要由 keep-alive 触发

    },
  }

</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
  .login-temp-container {
    box-sizing: border-box;
    height: 100%;

    .un-login {
      height: 100%;

      .fake-btn {
        width: 100%;
        font-size: 14px;
        color: $theme-lv1-color;
        border: 1px solid #FFFFFF;
        background: #FFFFFF;
        outline: none;

        &.el-button:hover,
        &.el-button:focus,
        &.el-button:active {
          color: none;
          border-color: none;
          background-color: none;
        }
      }
    }

    .yet-login {
      height: 100%;
      line-height: 1.5;
      position: relative;

      .user-info {
        display: flex;
        align-items: center;

        .user-headimg {
          margin-right: 16px;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden;

          .fake-img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
          }
        }

        .user-name {
          font-weight: 400;
          font-size: 16px;
          color: #FFFFFF;
        }

        height: 100%;
      }

      &:hover {
        .float-palce {
          display: initial;
        }
      }

      .float-palce {
        // transition: all 0.4s;
        display: none;
        // width: 140px;
        // height: 140px;
        position: absolute;
        top: $component-herder-height;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 4px;
        overflow: hidden;
        background-color: #fff;
        box-shadow: 0 0 14px -7px rgba(0, 0, 0, .6);

        .control-list {
          .cl-item {
            box-sizing: border-box;
            cursor: pointer;

            .cl-link {
              box-sizing: border-box;
              padding: 10px;
              display: inline-block;
              min-width: 100px;
              text-align: center;
              font-size: 14px;
              color: $theme-lv1-color;
              cursor: pointer;
            }

            &:hover {
              background: #f5f5f5;
            }

            &.log-out {
              color: $common-number1-color;
            }
          }
        }
      }
    }
  }

</style>
