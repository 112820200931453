// 全局缓存名称
const stroage_form = {
  login_token: 'login_token', // 登录令牌
  login_info: 'login_info', // 登录信息
  buffer_router: 'buffer_router', // 路由缓存
  invite_user_id: 'invite_user_id', // 邀请用户id
  city_form: 'city_form', // 城市列表缓存
  page_infe: 'page_info', // 页面信息
  web_base_info: 'web_base_info', // 网站基本信息
  browse_equipment: 'browse_equipment', // 浏览设备
  input_history: { // 输入历史
    stroage_name: 'input_history', // 存储名称
    child_name: { // 子集名称
      test: 'test', // 测试
      login_account: 'login_account', // 登录账号
      applicant: 'applicant', // 投保人
      recognizee: 'recognizee', // 被保人
    }
  },
}

export default stroage_form
