<template>
  <div class="my-dialog-container">
    <el-dialog
      width="500px"
      center
      :visible.sync="mapVisible"
      :close-on-click-modal="false"
      :before-close="dialogBeforeCloseFun">
      <div class="my-dialog">
        <h3 class="title" v-if="mapOptions.is_title">{{mapOptions.title}}</h3>
        <div class="main">
          <p class="message" :style="{textAlign: mapOptions.message_align}">{{mapOptions.message}}</p>
        </div>
        <ul class="control">
          <li class="item cancel" v-if="mapOptions.is_cancel">
            <el-button class="fake-btn" @click="_handleCancle">
              <span>{{mapOptions.cancel_text}}</span>
            </el-button>
          </li>
          <li class="item confirm"  v-if="mapOptions.is_confirm">
            <el-button class="fake-btn" @click="_handleConfirm">
              <span>{{mapOptions.confirm_text}}</span>
            </el-button>
          </li>
        </ul>
        <p class="tips" v-if="mapOptions.is_tips">{{mapOptions.tips}}</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  // 允许组件模板递归地调用自身
  name: 'MyDialog',
  // 声明一组可用于组件实例中的组件
  components: {

  },
  // 一个用于从父组件接收数据的数组或对象
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: {}
    }
  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {
      mapVisible: this.visible,
      mapOptions: Object.assign({
        is_confirm: true, // 是否显示确认按钮
        is_cancel: true, // 是否显示取消按钮
        is_title: true, // 是否显示标题
        is_tips: true, // 是否显示提示文本
        message_align: 'center',
        confirm_text: '确认',
        cancel_text: '取消',
        title: '标题',
        message: '描述',
        tips: '提示',
        sign: '', // 对话框标识
      },this.options)
    }
  },
  // 计算属性：
  computed: {

  },
  // 钩子函数--侦听data变动：
  watch: {
    visible: function(e) {
      this.mapVisible = e;
    },
    options: function(e) {
      console.log('this.mapOptions e == ',e)
      this.mapOptions = Object.assign(this.mapOptions,e);
      console.log('this.mapOptions == ',this.mapOptions)
    }
  },
  // 在实例创建完成后被立即同步调用
  methods: {
    // 关闭弹窗
    _handleClose () {
      // 告知弹窗变化
      this.informChangeFun();
    },
    // 确定按钮
    _handleConfirm () {
      this.informChangeFun({
        visible: false,
        status: 1, // 1/确认 2/取消 3/取消，关闭弹窗
        message: '确认',
        data: '',
        event: 'confirm'
      });
    },
    // 取消按钮
    _handleCancle () {
      this.informChangeFun({
        visible: false,
        status: 2, // 1/确认 2/取消 3/取消，关闭弹窗
        message: '取消',
        data: '',
        event: 'cancle'
      });
    },
    noop () {},
    /**
     * 关闭弹窗前
     */
    dialogBeforeCloseFun(done) {
      done();
      console.log('关闭弹窗前 == ',this.mapVisible)
      // 告知弹窗变化
      this.informChangeFun();
    },
    /**
     * 告知弹窗变化
     */
    informChangeFun(opt) {
      let default_data = {
        visible: false,
        status: 3, // 1/确认 2/取消 3/取消，关闭弹窗
        message: '取消，关闭弹窗',
        data: '',
        event: 'close',
        sign: this.mapOptions.sign,
      };
      let result = Object.assign(default_data,opt);
      this.$emit(default_data.event,result);
      // 默认变化通知
      this.informDefaultchangeFun(default_data);
    },
    /**
     * 默认变化通知
     */
    informDefaultchangeFun(opt) {
      this.$emit('on-change',opt);
    }
  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {

  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {

  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前

  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
.my-dialog-container {
  box-sizing: border-box;
  &::v-deep {
    .el-dialog {
      border-radius: 12px;
    }
  }
}

.my-dialog {
  $margin: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-size: 18px;
    color: #444343;
  }
  .main {
    width: 100%;
    box-sizing: border-box;
    padding: 28px 0 50px;
  }
  .message {
    color: #444343;
    font-size: 16px;
  }
  .tips {
    margin-top: $margin;
    font-size: 12px;
    color: #AAAAAA;
  }
  .control {
    display: flex;
    .item {
      margin-left: 30px;
      &::v-deep {
        .fake-btn {
          width: 100%;
          height: 30px;
          padding: 0 15px;
          font-size: 12px;
          color: #fff;
          border: 1px solid #E62129;
          background: #E62129;
          outline: none;
          &.el-button:hover,&.el-button:focus,&.el-button:active {
            color: none;
            border-color: none;
            background-color: none;
          }
          &.el-button:active {
            color: $active-default-color;
            border-color: $active-default-color;
            background-color: #fff;
          }
        }
      }
      &:first-of-type {
        margin-left: 0;
      }
      &.confirm {
        &::v-deep {
          .fake-btn {
            color: #fff;
            border: 1px solid #E62129;
            background: #E62129;
          }
        }
      }
      &.cancel {
        &::v-deep {
          .fake-btn {
            color: #fff;
            border: 1px solid #CCCCCC;
            background: #CCCCCC;
          }
        }
      }
    }
  }
}
</style>
