import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home/Home.vue'

import {routerMonitoring} from './router.monitoring'


// 缓存原型上的push函数
const originPush = VueRouter.prototype.push
const originReplace = VueRouter.prototype.replace
// 给原型对象上的push指定新函数函数
VueRouter.prototype.push = function (location, onComplete, onAbort) {
  // 判断如果没有指定回调函数, 通过call调用源函数并使用catch来处理错误
  if (onComplete===undefined && onAbort===undefined) {
    return originPush.call(this, location, onComplete, onAbort).catch(() => {})
  } else { // 如果有指定任意回调函数, 通过call调用源push函数处理
    // originPush.call(this, location, onComplete, onAbort)
    originPush.call(this, location).catch(err => err)
  }
}
// replace同理处理
VueRouter.prototype.replace = function (location, onComplete, onAbort) {
  if (onComplete===undefined && onAbort===undefined) {
    return originReplace.call(this, location, onComplete, onAbort).catch(() => {})
  } else {
    originReplace.call(this, location, onComplete, onAbort)
  }
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/Home.vue'),
  },
  { // 同步手机
    path: '/mobile',
    name: 'Mobile',
    component: () => import('@/views/mobile/Mobile.vue')
  },
  { // 登录-注册
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/Login.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/login/components/qrcode-login.vue'),
      },
      {
        path: '1',
        component: () => import('@/views/login/components/qrcode-login.vue'),
      },
      {
        path: '2',
        component: () => import('@/views/login/components/phone-login.vue'),
      },
      {
        path: '3',
        component: () => import('@/views/login/components/register-temp.vue'),
      },
      {
        path: '4',
        component: () => import('@/views/login/components/retrieve-temp.vue'),
      },
      {
        path: '5',
        component: () => import('@/views/login/components/amend-temp.vue'),
      },
      {
        path: '6',
        component: () => import('@/views/login/components/binding-temp.vue'),
      },
    ],
  },
  { // 发布
    path: '/issue',
    name: 'Issue',
    component: () => import('@/views/issue/Issue.vue'),
  },
  { // 需求与资源
    path: '/issue/common-form',
    name: 'Issue',
    component: () => import('@/views/issue/child-pages/common-form.vue'),
  },
  // { // 需求
  //   path: '/issue/demand-form',
  //   name: 'Issue',
  //   component: () => import('@/views/issue/child-pages/demand-form.vue'),
  // },
  // { // 资源
  //   path: '/issue/resource-form',
  //   name: 'Issue',
  //   component: () => import('@/views/issue/child-pages/resource-form.vue'),
  // },
  { // 竞标需求
    path: '/demand',
    name: 'Demand',
    component: () => import('@/views/demand/Demand.vue'),
  },
  { // 竞标需求详情
    path: '/demand/index',
    name: 'DemandDetails',
    component: () => import('@/views/demand/child-pages/demand-details/DemandDetails.vue'),
  },
  { // 会员中心
    path: '/vip',
    name: 'Member-center',
    // component: () => import('@/views/member-center/Member-center.vue'),
    component: () => import('@/views/member-center/versions/vip-one/VipOne.vue'),
  },
  { // 认证申请
    path: '/identification',
    name: 'Identification',
    component: () => import('@/views/identification/Identification.vue'),
  },
  { // 保险
    path: '/insurance',
    name: 'Insurance',
    component: () => import('@/views/insurance/Insurance.vue'),
  },
  { // 保险-保险详情
    path: '/insurance/index',
    name: 'InsuranceDetails',
    component: () => import('@/views/insurance/child-pages/insurance-details/InsuranceDetails.vue'),
  },
  // { // 保险-保单查询
  //   path: '/insurance/admin',
  //   name: 'Admin',
  //   component: () => import('@/views/insurance/child-pages/admin/Admin.vue'),
  // },
  { // 保险-保单查询
    path: '/insurance/query/index',
    name: 'QueryDetails',
    component: () => import('@/views/insurance/child-pages/query/QueryDetails.vue'),
  },
  { // 推广管理
    path: '/popularize',
    name: 'AccountAdmin',
    component: () => import('@/views/insurance/child-pages/account-admin/AccountAdmin.vue'),
  },
  { // 邀请好友
    path: '/invite',
    name: 'Invite',
    component: () => import('@/views/invite/Invite.vue'),
  },
  { // 成功提示
    path: '/succeed',
    name: 'Succeed',
    component: () => import('@/views/succeed/Succeed.vue'),
  },
  { // 成功提示-支付
    path: '/insurance/succeed-pay',
    name: 'SucceedPay',
    component: () => import('@/views/succeed-pay/SucceedPay.vue'),
  },
  { // 公共成功提示-支付
    path: '/insurance/succeed-common-pay',
    name: 'SucceedCommonPay',
    component: () => import('@/views/succeed-common-pay/SucceedCommonPay.vue'),
  },
  { // 404
    path: '*',
    name: 'PageNotFound',
    component: () => import('@/views/page-not-found/PageNotFound.vue'),
  },
  { // 没有权限
    path: '/PermissionDenied',
    name: 'PermissionDenied',
    component: () => import('@/views/permission-denied/PermissionDenied.vue'),
  },
  // {
  //   path: '/h5/login',
  //   name: 'h5Login',
  //   component: () => import('@/h5/views/login/Login.vue'),
  // },
  // {
  //   path: '/h5/success',
  //   name: 'h5Success',
  //   component: () => import('@/h5/views/status/Success.vue'),
  // },
  // {
  //   path: '/h5/error',
  //   name: 'h5Error',
  //   component: () => import('@/h5/views/status/Error.vue'),
  // }
]





const router = new VueRouter({
  mode: 'history',// 去掉井号
  routes,
  scrollBehavior (to, from, savedPosition) {
    // console.log('scrollBehavior to == ',to)
    // console.log('scrollBehavior from == ',from)
    // console.log('scrollBehavior savedPosition == ',savedPosition)
    return {
      x: 0,
      y: 0,
      behavior: 'smooth',
    }
  }
})


// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  let _this = Vue.prototype;
  // _this.mySetStorage({
  //   name: _this.STORAGE.buffer_router,
  //   value: to,
  //   expires: 5000,
  // });

  // 获取缓存
  let buffer_router = _this.myGetStorage(_this.STORAGE.buffer_router);
  console.log('buffer_router == ',buffer_router)

  // 获取/设置-邀请用户缓存
  // _this.mySetAndGetInviteUserId(to);

  // to 将要访问的路径
  // from 代表从哪个路径跳转而来
  // next 是一个函数，表示放行
  // next()放行 next('/login') 强制跳转

  console.log('to == ',to)
  console.log('from == ',from)
  // console.log('next == ',next)


  // 如果是跳转保险页面，则先将数据共享再执行跳转
  if(to.path == '/insurance') {
    console.log('to.path == "/insurance" == ',to.path == '/insurance')
    // 共享缓存到保险页面
    if (_this.shareWin) {
      _this.shareWin.sandPostMessage(_this.shareWin.childWin)
    }
  }


  // 路由监控-二级域名跳转
  routerMonitoring(to,from)




  if (to.matched.length !== 0) {

    // 如果没有子账号管理权限则跳转其他页面
    if(to.path == '/popularize') {
      // 获取账号信息
      _this.myGetUserInfoFun().then((flag) => {
        if(!flag) {
          next('/PermissionDenied')
        } else {
         next()
        }
      });
    } else {
      next()
    }
  } else {
    next({ path: '*' })
  }

  // if (to.path === '/login') return next()

  // // 获取token

  // const tokenStr = window.sessionStorage.getItem('token')

  // if (!tokenStr) return next('/login')

  // next()

})

export default router
