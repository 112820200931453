<template>
  <div id="app">
    <el-header>
      <Header />
    </el-header>
    <el-container>
      <el-main>
        <router-view></router-view>
      </el-main>
      <el-footer>
        <Footer />
      </el-footer>
    </el-container>
  </div>
</template>

<script>
  // 手机调试器
  // import VConsole from 'vconsole';
  // const vConsole = new VConsole();
  import DevicePixelRatio from '@/utils/devicePixelRatio.js';
  import Header from '@/components/header/Header.vue'
  import Footer from '@/components/footer/Footer.vue'
  // 计算页面打开初始缩放比例
  var initialScale = window.screen.width / 1500;
  export default {
    name: 'app',
    metaInfo() {
      return {
        title: this.web_base_info.seo.site_title, // set a title
        meta: [{ // set meta
          name: 'keywords',
          content: this.web_base_info.seo.site_keywords,
        }, {
          name: 'description',
          content: this.web_base_info.seo.site_description,
        }, {
          name: 'generator',
          content: this.generator,
        }, {
          name: 'application-name',
          content: this.web_base_info.seo.site_title,
        }, {
          name: 'msapplication-tooltip',
          content: this.web_base_info.seo.site_title,
        }, {
          name: 'viewport',
          content: 'width=device-width, initial-scale=' + initialScale +
            ', minimum-scale=0.2, maximum-scale=3.0, user-scalable=1',
        }],
        // link: [{ // set link
        //   rel: 'asstes',
        //   href: 'https://assets-cdn.github.com/'
        // }]
      }
    },
    data() {
      return {
        facility: {}, // 当前设备
        web_base_info: { // 网站基本信息
          seo: {},
        },
        generator: window.location.href,
      }
    },
    components: {
      Header,
      Footer
    },
    // 钩子函数--侦听data变动：
    watch: {
      // 监听路由变化，并同步到导航组件
      $route(to, from) {
        this.generator = window.location.href;
      }
    },
    methods: {
      /**
       * 获取页面数据
       */
      getPageDataFun() {
        // 获取设备信息
        this.getFacilityInfo();
        // PC端页面等比例缩放
        // this.pageZoomFun();
        // 网站基本信息
        this.getSerivceInfoFun();

        console.log('process.env.NODE_ENV == ', process.env.NODE_ENV)
      },
      /**
       * 获取设备信息
       */
      getFacilityInfo() {
        let facility = this.myGetCurrentFacility();
        console.log('获取设备信息 facility == ', facility)
        this.facility = facility;
      },
      /**
       * 网站基本信息
       */
      getSerivceInfoFun() {
        this.myRequest({
          url: '/api/site/base',
        }).then((res) => {
          console.log('获取服务信息 res == ', res);
          let resData = res.data.data;
          this.web_base_info = resData;
          // 储存缓存-网站基本信息
          this.mySetStorage({
            name: this.STORAGE.web_base_info,
            value: resData,
            // expires: 2000, // 时间：存储时长，毫秒数
          });
        })
      },
      /**
       * PC端页面等比例缩放
       */
      pageZoomFun() {
        let {
          facility
        } = this.facility;
        console.log('执行校正浏览器缩放比例 facility == ', facility)
        if (facility && facility == 'mobile') return;
        new DevicePixelRatio().init();
      },
    },
    // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
    created() {
      // 获取页面数据
      this.getPageDataFun()
    },
    // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
    mounted() {

    },
  }

</script>

<style lang="scss">
  #app {
    box-sizing: border-box;
    min-width: $theme-view-widht;
    // min-height: 100vh;
    // overflow: hidden;
    font-family: $common-default-font-family;

    // display: flex;
    // flex-direction: column;
    // & > .main {
    //   position: relative;
    //   flex: 1;
    //   min-height: 0;
    //   display: flex;
    //   flex-direction: column;
    // }
    .el-header,
    .el-footer,
    .el-main {
      padding: 0;
    }

    .el-header {
      height: $component-herder-height !important;
    }

    .el-footer {
      height: auto !important;
    }

    .el-main {
      min-height: 100vh;
      overflow: hidden;
    }
  }

</style>
