import Vue from 'vue'
import Vuex from 'vuex'
const vue_this = Vue.prototype

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    is_login_status: false, // 登录状态
    header_menu_status: '/', // 头部菜单
    login_page_status: 1, // 登录页面状态 0/待定 1/扫码登录 2/手机号登录 3/注册 4/忘记密码/找回密码
  },
  mutations: {
    isLoginStatusChange (state,status) { // 登录状态改变
      console.log('isLoginStatusChange 执行了 == ',status)
      state.is_login_status = status;
      console.log('e 执行了 == ',vue_this)
      console.log('e 执行了 == ',vue_this.shareWin)
      try {
        if(vue_this.shareWin) {
          vue_this.shareWin.sandPostMessage(vue_this.shareWin.childWin)
        }
      } catch(err) {}
    },
    headerMenuStatusChange (state,status) { // 头部菜单状态改变
      state.header_menu_status = status;
    },
    loginPageStatusChange (state,status) { // 登录页面状态改变
      state.login_page_status = status;
    },
  },
  actions: {
  },
  modules: {
  }
})
