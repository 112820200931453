<template>
  <div class="container">
    <div class="custom-servers-module">
      <div class="csm-list">
        <div class="csm-item" v-for="(item,index) in custom_servers" :key="index">
          <div class="csm-img">
            <el-image
              class="fake-img"
              :src="item.icon"
              fit="scale-down"
            ></el-image>
          </div>
          <div class="csm-info">
            <div class="csm-number">
              <div class="dot-line one" :style="{backgroundImage: 'url(\''+ ossUrl +'/images/home-img/custom-servers-dot.png\')'}"></div>
              <div class="dot-line two" :style="{backgroundImage: 'url(\''+ ossUrl +'/images/home-img/custom-servers-dot.png\')'}"></div>
              <div class="number-img">
                <el-image
                  class="fake-img"
                  :src="item.number"
                  fit="scale-down"
                ></el-image>
              </div>
            </div>
            <div class="csm-text">{{item.text_1}}<br />{{item.text_2}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 允许组件模板递归地调用自身
  name: 'custom-servers',
  // 声明一组可用于组件实例中的组件
  components: {

  },
  // 一个用于从父组件接收数据的数组或对象
  props: {

  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {
      ossUrl: this.GLOBAL.ossUrl,
      custom_servers: [],
    }
  },
  // 计算属性：
  computed: {

  },
  // 钩子函数--侦听data变动：
  watch: {

  },
  // 在实例创建完成后被立即同步调用
  methods: {
    /**
     * 初始化-定制服务
     */
    initCustomServersFun() {
      let { ossUrl } = this;
      let form = [{
        icon: ossUrl + '/images/home-img/custom-servers-icon (1).png',
        number: ossUrl + '/images/home-img/custom-servers-number (1).png',
        text_1: '投标用户',
        text_2: '发布需求',
      },{
        icon: ossUrl + '/images/home-img/custom-servers-icon (2).png',
        number: ossUrl + '/images/home-img/custom-servers-number (2).png',
        text_1: '竞标公司',
        text_2: '提交方案',
      },{
        icon: ossUrl + '/images/home-img/custom-servers-icon (3).png',
        number: ossUrl + '/images/home-img/custom-servers-number (3).png',
        text_1: '投标用户',
        text_2: '选择方案',
      },{
        icon: ossUrl + '/images/home-img/custom-servers-icon (4).png',
        number: ossUrl + '/images/home-img/custom-servers-number (4).png',
        text_1: '双方沟通',
        text_2: '达成协议',
      },{
        icon: ossUrl + '/images/home-img/custom-servers-icon (5).png',
        number: ossUrl + '/images/home-img/custom-servers-number (5).png',
        text_1: '开展活动',
        text_2: '任务结束',
      }]

      this.custom_servers = form;
    },
  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {

  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {
    // 初始化-定制服务
    this.initCustomServersFun();
  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前

  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
.container {
  box-sizing: border-box;
}

.custom-servers-module {
  .csm-list{
    $line-height: 100px;
    display: flex;
    justify-content: space-between;
    .csm-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      // min-height: 428px;
      &:nth-child(2n) {
        flex-direction: column-reverse;
        .csm-info {
          flex-direction: column-reverse;
          margin-top: 0;
          margin-bottom: $line-height;
          &::before {
            top: 100%;
            transform: translateY(0%);
          }
          .csm-number {
            .dot-line{
              display: initial;
              width: calc(32px * 11);
              height: 8px;
              z-index: 1;
              position: absolute;
              background-repeat: repeat;
              background-size: 16px;
              &.one {
                right: -6px;
                top: 109px;
                transform: rotate(145deg);
              }
              &.two {
                top: 110px;
                left: -6px;
                transform: rotate(35deg);
              }
            }
          }
        }
      }
      &:nth-child(2) {
        .csm-img {
          background: #F2E5B0;
        }
      }
      &:nth-child(3) {
        .csm-img {
          background: #E7B592;
        }
      }
      &:nth-child(4) {
        .csm-img {
          background: #B5DCF9;
        }
      }
      &:nth-child(5) {
        .csm-img {
          background: #B9E4DD;
        }
      }
      .csm-img {
        position: relative;
        box-sizing: border-box;
        width: 180px;
        height: 180px;
        border-radius: 50%;
        overflow: hidden;
        background: #84CCC9;
        box-shadow: 1px 3px 1px 0 rgba(0,0,0,.6);
        text-align: center;
        font-size: 0;
        .fake-img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          width: 150px;
          height: 120px;
        }
      }
      .csm-info {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-top:  $line-height;
        &::before {
          content: '';
          position: absolute;
          top: -100%;
          left: 50%;
          transform: translate(-50%,12%);
          width: 1px;
          height: $line-height;
          background: #51ADEC;
        }
        .csm-number {
          margin: 0 auto;
          position: relative;
          .dot-line {
            display: none;
          }
          .number-img {
            z-index: 2;
            position: relative;
            width: 46px;
            height: 46px;
            border-radius: 50%;
            overflow: hidden;
            background: #53AEEC;
            .fake-img {
              position: absolute;
              top: 65%;
              left: 58%;
              transform: translate(-50%,-50%);
              width: 42px;
              height: 38px;
            }
          }
        }
        .csm-text {
          line-height: 1.5;
          font-size: 22px;
          color: #444343;
        }
      }
    }
  }
}
</style>
