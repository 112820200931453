<template>
  <div class="container">
    <div class="header">
      <div class="header-main">
        <div class="logo" @click="logoSkipFun">
          <el-image v-if="clientSource == 'baiduboxapp'" class="fake-img" :src="ossUrl + '/images/logo-img/head-logo-2.png'" fit="scale-down" lazy></el-image>
          <el-image v-else class="fake-img" :src="ossUrl + '/images/logo-img/head-logo.png'" fit="scale-down" lazy></el-image>
        </div>
        <div class="menu">
          <el-menu :default-active="activeIndex" class="fake-menu" :router="true" mode="horizontal"
            @select="handleSelect" background-color="#1D232B" text-color="#fff" active-text-color="#ffd04b">
            <el-menu-item index="/" v-if="clientSource == 'baiduboxapp'" style="color:#999">
              首页
            </el-menu-item>
            <el-menu-item index="/" v-else>
              首页
            </el-menu-item>

            <el-menu-item index="/issue" v-if="clientSource == 'baiduboxapp'" style="color:#999">
              我要发布
            </el-menu-item>
            <el-menu-item index="/issue" v-else>
              我要发布
            </el-menu-item>

            <el-menu-item index="/demand" v-if="clientSource == 'baiduboxapp'" style="color:#999">
              竞标需求
            </el-menu-item>
            <el-menu-item index="/demand" v-else>
              竞标需求
            </el-menu-item>

            <el-menu-item index="/vip" v-if="clientSource == 'baiduboxapp'" style="color:#999">
              会员中心
            </el-menu-item>
            <el-menu-item index="/vip" v-else>
              会员中心
            </el-menu-item>

            <el-menu-item index="/identification" v-if="clientSource == 'baiduboxapp'" style="color:#999">
              认证申请
            </el-menu-item>
            <el-menu-item index="/identification" v-else>
              认证申请
            </el-menu-item>

            <el-menu-item index="/insurance" v-if="isLogin">
              保险
            </el-menu-item>
            <el-menu-item index="/login?id=1&page=1" v-else>
              保险
            </el-menu-item>

            <el-menu-item index="/popularize" v-if="isShow_som_account" >
              <div v-if="clientSource == 'baiduboxapp'" style="color:#999">推广管理</div>
              <div v-else>推广管理</div>
            </el-menu-item>
            <!-- <el-menu-item index="6">
              <router-link class="menu-link" to="/mobile">
                同步手机
              </router-link>
            </el-menu-item> -->
          </el-menu>
        </div>
        <div class="login">
          <router-link to="/invite" v-if="clientSource !== 'baiduboxapp'">
            <!-- 打开这个需要将 el-menu-item 的 padding 设置为 padding: 0 12px; -->
            <div class="invite-friends">
              <el-image class="fake-img" :src="ossUrl + '/images/logo-img/invite-friends.png'" fit="scale-down" lazy>
              </el-image>
            </div>
          </router-link>
          <div class="applet" v-if="clientSource !== 'baiduboxapp'">
            <div class="applet-icon">
              <i class="iconfont icon-xiaochengxu"></i>
            </div>
            <span>小程序</span>
            <div class="float-palce">
              <img class="fake-img" :src="ossUrl + '/images/logo-img/applet-qrcode.jpg'" alt="佰团乐小程序二维码">
              <!-- <el-image
                class="fake-img"
                :src="ossUrl + '/images/logo-img/applet-qrcode.jpg'"
                fit="scale-down"
                lazy
              ></el-image> -->
            </div>
          </div>
          <div class="login-register">
            <login-temp />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    mapState,
    mapMutations
  } from 'vuex';
  import loginTemp from '@/components/login-temp/LoginTemp';
  export default {
    // 允许组件模板递归地调用自身
    name: 'Header',
    // 声明一组可用于组件实例中的组件
    components: {
      loginTemp,
    },
    // 一个用于从父组件接收数据的数组或对象
    props: {

    },
    // 该函数返回组件实例的 data 对象
    data() {
      return {
        ossUrl: this.GLOBAL.ossUrl,
        clientSource: this.GLOBAL.clientSource,
        activeIndex: '/',
        isShow_som_account: false, // 是否显示子账号选项
        isLogin: false, // 是否显示子账号选项
      }
    },
    // 计算属性：
    computed: {
      ...mapState(['header_menu_status']),
    },
    // 钩子函数--侦听data变动：
    watch: {
      // 监听路由变化，并同步到导航组件
      $route(to, from) {
        // console.log('to == ',to);
        // console.log('from == ',from);
        this.activeIndex = to.path;
        // 计算路径的路由高亮
        this.computedPathRouterFun(to.path);
        // 获取登录状态
        this.getLoginStatusFun();
        // 获取页面数据
        this.getPageDataFun();
      }
    },
    // 在实例创建完成后被立即同步调用
    methods: {
      ...mapMutations(['headerMenuStatusChange', 'isLoginStatusChange']),
      getUrlParam(name) {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
      },
      /**
       * 点击logo跳转
       */
      logoSkipFun() {
        if (this.activeIndex == '/') {
          this.$router.replace('/');
          this.$router.go(0);
        } else {
          this.$router.push('/');
        }
      },
      /**
       * 获取当前请求头
       */
      getCurrentHeaderFun() {
        let login_page = this.getUrlParam("page"); //参数名
        // 规则匹配-保险模块
        let regexp1 = /insurance/g;
        //if(this.$route.path=="/insurance"){//login_page ||
        if(regexp1.test(this.$route.path)) {
          this.clientSource = 'baiduboxapp';
        }else{
          this.clientSource = 'pc';
        }
        var req = new XMLHttpRequest();
        req.open('GET', document.location.href, false);
        req.send(null);
        var headers = req.getAllResponseHeaders();

        var arr = headers.trim().split(/[\r\n]+/);
        var headerMap = {};
        arr.forEach(function (line) {
          var parts = line.split(': ');
          var header = parts.shift();
          var value = parts.join(': ');
          headerMap[header] = value;
        });

        console.log('获取当前请求头 headerMap == ', headerMap);
        return headerMap;
      },
      /**
       * 获取登录状态
       */
      getLoginStatusFun() {

        // Cookie 获取缓存-登录状态
        // let login_token = this.myGetCookie(this.STORAGE.login_token);

        // 是否是微信扫码登录
        let is_qrcode_login = this.getCurrentHeaderFun(); // 获取当前请求头

        // 登录缓存
        let login_token = this.myGetStorage(this.STORAGE.login_token);
        // let login_token = true;

        console.log('缓存-登录状态 login_token == ', login_token)
        console.log('缓存-登录状态 login_token == "undefined" == ', login_token == 'undefined')

        if (!(is_qrcode_login.is_qrcode_login) && (String(login_token) == 'null' || typeof login_token == 'undefined' ||
            String(login_token) == 'false')) {
          console.log('获取登录状态 1/if 为 login_token == ', login_token)
          this.isLoginStatusChange(false);
        } else {
          console.log('获取登录状态 2/else 为 login_token == ', login_token)
          this.isLoginStatusChange(true);
          // 登录用户-信息
          let user_info = this.myGetStorage(this.STORAGE.login_info);
          console.log('------------强制绑定手机号 user_info == ', user_info)
          // console.log('------------强制绑定手机号',user_info.mobile)
          if (user_info && !(JSON.stringify(user_info) == "{}")) {
            this.isLogin = true;
            if (user_info.mobile != 'undefined') {
              if (!user_info.mobile) {
                // 验证当前是否已在绑定手机号页面
                if ('/login/6' != this.$route.path) {
                  // 强制跳转绑定手机号页面
                  this.$router.replace("/login/6");
                }
                this.myMessage({
                  message: '请您先绑定手机号',
                });
              }
            }
          } else {
            // 发起登录请求
            this.loginRequestFun();
          }
        }
      },
      /**
       * 发起登录请求
       */
      loginRequestFun() {
        console.log('发起登录请求');
        // 登录信息请求
        this.myLoginInfoRequest().then((res) => {
          // vuex 调用函数 改变状态
          console.log('发起登录请求 this.$store == ', this.$store);
          // Cookie 设置缓存-登录状态
          this.mySetCookie(this.STORAGE.login_token, true);
          // this.mySetCookie(this.STORAGE.login_token,true,60*60*24);
          // 储存缓存-登录状态
          this.mySetStorage({
            name: this.STORAGE.login_token,
            value: true,
            // expires: 1000 * 60 * 60 * 24, // 时间：存储时长，毫秒数
          });
          this.isLoginStatusChange(true);
          if (this.shareWin) {
            this.shareWin.sandPostMessage(this.shareWin.childWin)
          }
          this.$router.go(0);
        });
      },
      handleSelect(index, indexPath) {
        console.log(index, indexPath);
        console.log('index == ', index);
        console.log('indexPath == ', indexPath);
        console.log('this.activeIndex == ', this.activeIndex);
        this.myRemoveSession('tabs-temp')
        this.mySetSession('Insurance', 'tabs')
        if (this.activeIndex == '/insurance' && index == this.activeIndex) {
          this.$router.replace('/insurance');
          this.$router.go(0);
          // this.myRemoveSession('tabs-temp')
          // this.mySetSession('Insurance','tabs')
          return;
        } else if (index == '/insurance') {
          this.$router.push('/insurance');
        }
        // this.headerMenuStatusChange(index);
        // 计算路径的路由所属高亮
        this.computedPathRouterFun(index);
      },
      /**
       * 计算路径的路由高亮
       */
      computedPathRouterFun(str) {
        // console.log('路由高亮 str ==', str)

        if (str == '/') {
          this.activeIndex = str;
          return;
        };

        let regexp = /^\/.[^/]+/;
        let result = regexp.exec(str);
        if (result.length > 0 && result[0]) {
          this.activeIndex = result[0];
        }
        // console.log('路由高亮 result ==', result)
        // console.log('路由高亮 this.activeIndex ==', this.activeIndex)
      },
      /**
       * 获取页面数据
       */
      getPageDataFun() {
        // 获取账号信息
        this.myGetUserInfoFun().then((res) => {
          this.isShow_som_account = res;
        });
      },
    },
    // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
    created() {

    },
    // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
    mounted() {

    },
    beforeCreate() { // 创建前

    },
    beforeMount() { // 挂载前

    },
    beforeUpdate() { // 更新前

    },
    updated() { // 更新后

    },
    beforeDestroy() { // 销毁前

    },
    destroyed() { // 销毁后

    },
    activated() { // 缓存保持，需要由 keep-alive 触发

    },
  }

</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
  .container {
    box-sizing: border-box;
    min-height: $component-herder-height;
  }

  .header {
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    box-sizing: border-box;
    // padding: 0 $theme-whiteedge;
    background-color: $theme-default-color;

    .header-main {
      box-sizing: border-box;
      padding: 0 $theme-widht-whiteedge;
      // width: $theme-view-widht;
      max-width: $theme-view-widht;
      min-width: $theme-view-min-widht;
      margin: 0 auto;
      display: flex;
    }

    .logo {
      box-sizing: border-box;
      padding: 8px;
      height: $component-herder-height;

      .fake-img {
        width: auto;
        height: 100%;

        .el-image__error,
        .el-image__inner,
        .el-image__placeholder,
        img {
          width: auto !important;
          height: 100%;
        }
      }
    }

    .menu {
      flex: 1;
      min-width: 0;
      box-sizing: border-box;
      padding: 0 24px;

      .fake-menu {
        &:not(.el-menu--collapse) {
          height: $component-herder-height;
        }
      }

      .el-menu {
        background: initial;
        border-bottom: initial;
      }

      .el-menu-item {
        height: $component-herder-height;
        line-height: $component-herder-height;
        font-size: 16px;
        // padding: 0 16px;
        padding: 0 12px;
      }

      .menu-link {
        display: inline-block;
        width: 100%;
        height: 100%;
      }
    }

    .login {
      display: flex;
      align-items: center;
      height: $component-herder-height;
      line-height: $component-herder-height;

      .applet {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #fff;

        .applet-icon {
          width: 35px;
          height: 35px;
          line-height: 35px;
          text-align: center;

          .iconfont {
            box-sizing: border-box;
            padding-right: 4px;
            font-size: 28px;
            color: #fff;
            transform: scale(1.3);
          }
        }

        &:hover {
          .float-palce {
            display: initial;
          }
        }

        .float-palce {
          // transition: all 0.4s;
          display: none;
          width: 140px;
          height: 140px;
          position: absolute;
          top: $component-herder-height;
          left: 50%;
          transform: translateX(-50%);
          box-shadow: 0 0 14px -7px rgba(0, 0, 0, .6);
          border-radius: 4px;
          overflow: hidden;

          .fake-img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
          }
        }
      }

      .login-register {
        margin-left: 24px;
        height: 100%;
      }

      .invite-friends {
        box-sizing: border-box;
        padding: 8px;
        // width: 230px;
        height: $component-herder-height;

        .fake-img {
          width: auto;
          // max-width: 230px;
          height: 100%;

          .el-image__error,
          .el-image__inner,
          .el-image__placeholder,
          img {
            width: auto !important;
            height: 100%;
          }
        }
      }
    }
  }
  .font-color-999{
    color:#999
  }

</style>
