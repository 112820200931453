<template>
  <div class="home-title-module">
    <!-- <div class="embellish">
      <i class="iconfont icon-arrow-left-1-icon" :style="{color: titleColor}"></i>
    </div> -->
    <div class="htm-body">
      <h1 class="title" :style="{color: titleColor}">{{title}}</h1>
      <p class="descriptions" :style="{color: descriptionsColor}">{{descriptions}}</p>
    </div>
    <!-- <div class="embellish">
      <i class="iconfont icon-arrow-right-1-icon" :style="{color: titleColor}"></i>
    </div> -->
  </div>
</template>

<script>
export default {
  // 允许组件模板递归地调用自身
  name: '',
  // 声明一组可用于组件实例中的组件
  components: {

  },
  // 一个用于从父组件接收数据的数组或对象
  props: {
    title: {
      type: String,
      default: 'title',
    },
    descriptions: {
      type: String,
      default: 'descriptions',
    },
    titleColor: {
      type: String,
      default: '#444343',
    },
    descriptionsColor: {
      type: String,
      default: '#787878',
    },
  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {

    }
  },
  // 计算属性：
  computed: {

  },
  // 钩子函数--侦听data变动：
  watch: {

  },
  // 在实例创建完成后被立即同步调用
  methods: {

  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {

  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {

  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前

  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
.home-title-module {
  box-sizing: border-box;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .embellish {
    width: 40px;
    height: 40px;
    line-height: 40px;
    // background-color: #DFEBFB;
    &.inverse {
      transform: rotate(-180deg);
    }
    .iconfont {
      font-size: 36px;
      color: #444343;
    }
  }
  .htm-body {
    margin: 0 20px;
    text-align: center;
    .title {
      font-size: 36px;
      color: #444343;
    }
    .descriptions {
      margin-top: 18px;
      font-size: 14px;
      color: #787878;
    }
  }
}
</style>
