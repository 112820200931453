/**
 * 说在前面：推荐使用【匿名函数】或【函数表达式】挂载全局函数
 * 注意：箭头函数的 this 指向为 undefined。
 */

"use strict"; // 这里是严格模式

// const test0 = () => {
//   console.log('全局函数0 test this',this)
// };
import Storage from '@/utils/my_storage.js';
import Cookie from '@/utils/my_cookie.js';
import Session from '@/utils/my_session.js';
import { verifyFileTypeFun, getSuffixFun, cerateFileNameFun, getMobileModelFun } from '@/utils/utils_fun.js';
// import { download } from '@/utils/download.js';
// 检验工具包
import Checktool from '@/utils/check_fun.js';
const myChecktool = new Checktool();
import { saveAs } from 'file-saver'; // 为了适应移动端下载
import qs from 'qs';

// 【推荐】
function test1() {
  console.log('全局函数1 test this',this)
};

// 【推荐】
const test2 = function() {
  console.log('全局函数2 test this',this)
};

let limitOptions = {
  message: {
    name: null,
    status: true,
    timer: null,
    delay: 800,
    open: function() {
      this.status = true;
    },
    shut: function() {
      this.status = false;
    },
    closePageMessageFun: function() {
      let {
        name
      } = this;
      if (name) {
        name.close();
        name = null;
      }
    },
    closeTimerFun: function() {
      let {
        timer
      } = this;
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
    },
  },
};

/** 请求函数封装
 * @author 猫吃鱼
 * @param {Object} config 参数
 */
function myRequest(config,options) {
  const _this = this;
  config = config || {};
  if(config.data) {
    config.data = qs.stringify(config.data);
  }
  // API返回错误时是否重定向跳转
  if(config.redirect!=false) {
    config.redirect = true;
  }
  // 是否提示错误信息
  if(config.errorHint!=false) {
    config.errorHint = true;
  }

  let default_options = {
    is_loading: true,
  };
  default_options = Object.assign(default_options,options);

  let loading = null;

  // 如果没有传入 url 字段则返回错误
  if(!config.url) {
    return Promise.reject('错误 url 必填')
  }

  // 如果是默认的 /api 开头，则为自己的服务api,否则为第三方服务api
  let is_default_request = this.REGEXP.is_default_request.test(config.url);
  let baseURL = is_default_request ? '' : this.GLOBAL.baseUrl;

  //创建axios实例
  const instance = this.$axios.create({
    withCredentials: true,
    baseURL: baseURL,
    timeout: 50000,
    method: 'post',
    params: {
      'application_client_type': 'pc',
    }
  })

  return new Promise((resolve,reject) => {
    if(default_options.is_loading) {
      loading = this.myLoading();
    }
    //发送真正的网络请求
    instance(config).then(res => {
      if(loading) {
        loading.close();
      }
      console.log('真正的网络请求 res == ',res);
      if(res.data.code == 0) {
        resolve(res);
      } else {
        // reject(res.data.msg);
        // reject(res);
        console.log('请求失败:',res);
        // 登录失效则跳转至登录页面
        if(res.data.code=='-400'){
          // if(config.redirect){
          //   // this.$router.push("/login")
          //   this.$router.push("/login?id=1")
          // }
          // if(config.errorHint){
          //   if(limitOptions.message.status) {
          //     this.myMessage({
          //       message: '请您先登录',
          //     });
          //     limitOptions.message.shut();
          //   }
          // } else {
          //   if(!limitOptions.message.status) {
          //     limitOptions.message.open();
          //   }
          // }

          // this.myMessage({
          //   message: '请您先登录',
          //   duration: 500,
          //   onClose () {
          //     if(config.redirect){
          //       _this.$router.push("/login?id=1")
          //     }
          //   }
          // });


          // 全局-退出登录请求
          this.mylogOutRequestFun().then((res) => {});

          if(config.errorHint){
            if(limitOptions.message.status) {
              limitOptions.message.name = this.myMessage({
                message: '请您先登录',
                duration: limitOptions.message.delay,
                onClose () {
                  if(config.redirect){
                    _this.$router.push("/login?id=1")
                    limitOptions.message.open();
                  }
                }
              });
              limitOptions.message.shut();
            } else {
              limitOptions.message.closeTimerFun();
              limitOptions.message.timer = setTimeout(() => {
                limitOptions.message.closePageMessageFun();
              },limitOptions.message.delay);
            }
          } else {
            if(!limitOptions.message.status) {
              limitOptions.message.open();
            }
            if(config.redirect){
              _this.$router.push("/login?id=1")
            }
          }

        }else if(res.data.code=='-401'){
          if(config.redirect){
            // 强制跳转绑定手机号页面
            this.$router.push("/login/6")
          }
          if(config.errorHint){
            this.myMessage({
              message: '请您先绑定手机号',
            });
          }
        }else{
          if(config.errorHint){
            let err_msg = res.data.msg;
            this.myMessage({
              message: err_msg,
            });
          }
          reject(res);
        }

      }
    }).catch((err) => {
      console.log('请求失败:',err);
      reject(err);
    })
  });
};

/**
 * 退出登录请求
 */
function mylogOutRequestFun() {
  return new Promise((resolve,reject) => {
    this.myRequest({
      url: '/api/login/logout',
    }).then((res) => {
      console.log('退出登录 res == ',res);

      // Cookie 删除息缓存-登录状态
      this.myRemoveCookie(this.STORAGE.login_token);

      // // 删除息缓存-登录状态
      this.myRemoveStorage(this.STORAGE.login_token);

      // 删除缓存-登录信息
      this.myRemoveStorage(this.STORAGE.login_info);

      // 储存缓存-登录状态
      this.mySetStorage({
        name: this.STORAGE.login_token,
        value: false,
        // expires: 1000 * 60 * 60 * 24, // 时间：存储时长，毫秒数
      });

      // 储存缓存-登录状态
      this.mySetStorage({
        name: this.STORAGE.login_info,
        value: {},
        // expires: 1000 * 60 * 60 * 24, // 时间：存储时长，毫秒数
      });

      resolve(res);
    }).catch((err) => {
      console.log('退出登录 err == ',err)
      // reject();
    })
  });
}

/** 登录信息请求
 * @author 猫吃鱼
 * @param {Object} config 参数
 */
function myLoginInfoRequest() {
  return new Promise((resolve,reject) => {
    this.myRequest({
      method: 'post',
      url: '/api/user/center',
      redirect: false,
      errorHint: false,
    }).then(res => {
      console.log('登录信息请求 res == ',res);
      if(res.data.code==0){
        let resData = res.data.data;

        // Cookie 储存缓存-登录状态
        // this.mySetCookie(this.STORAGE.login_token,true,60*60*24);
        this.mySetCookie(this.STORAGE.login_token,true);

        // 储存缓存-登录状态
        this.mySetStorage({
          name: this.STORAGE.login_token,
          value: true,
          // expires: 1000 * 60 * 60 * 24, // 时间：存储时长，毫秒数
        });

        // 储存缓存-登录信息
        this.mySetStorage({
          name: this.STORAGE.login_info,
          value: resData,
          // expires: 2000, // 时间：存储时长，毫秒数
        });
        resolve(res);
      }else{
        reject();
      }
    }).catch(err => {
      console.log('登录信息请求 err == ',err);
      reject();
    })
  });
}

/** 获取oss签名
 * @author 猫吃鱼
 * @param {Object} file // 要上传的文件
 */
 function myGetOssSignature(file) {
  console.log('Global 获取oss签名 file == ',file)
  // 判断参数是否为空
  // if(!file || !file.raw) throw new Error('Error: The file is empty!');

  // 文件类型
  // let { type } = file.raw;
  // if(type) {
  //   if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(type)) {
  //     this.$message({
  //       message: '图片类型要求：jpeg、jpg、png',
  //       type: "error"
  //     });
  //     return false
  //   }
  // }

  let suffix = getSuffixFun(file);  // 获取文件后缀
  let { name } =  file;
  if(typeof name == 'string' && name.indexOf('.') != -1) {
    suffix = '.' + verifyFileTypeFun(name);  // 获取文件后缀
  }
  let fileName = cerateFileNameFun(); // 生成文件名称


  // 返回结果
  return new Promise((resolve,reject) => {
    // 发送请求
    this.myRequest({
      url: '/api/alioss/aliosspolicy'
    }).then(res => {
      let resData = res.data.data;
      let { accessid, callback, dir, expire, host, policy, signature } = resData;
      let key = dir + fileName + suffix;
      let result = {
        "path": host + '/' + key,
        "key": key,
        "policy": policy,
        "OSSAccessKeyId": accessid,
        "signature": signature,
      };

      // 成功
      resolve(result);
    }).catch(() => {
      // 失败
      reject();
    })
  })
}

/**
 * 上传文件到服务器
 * @param {Object} params OSS 签名
 * @param {file} file 图片文件
 */
 function myUploadFileToServers(params,file) {


  return new Promise((resolve,reject) => {
    var param = new FormData()  // 创建form对象

    param.append('key', params.key)  //
    param.append('policy', params.policy)  //
    param.append('OSSAccessKeyId', params.OSSAccessKeyId)  //
    param.append('signature', params.signature)  //
    param.append('file', file)  // 通过append向form对象添加数据
    this.$axios({
      url: this.GLOBAL.ossBaseUrl,
      method: 'post',
      data: param,
      headers: {'Content-Type': 'multipart/form-data'}
    }).then((res) => {
      console.log('图片上传成功',res)
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  });
}

/** 获取当前路由
 * @author 猫吃鱼
 * @param {Object} config 参数
 */
function myGetRouter() {
  return new Promise((resolve,reject) => {
    console.log('全局获取路由 == ',this.$route)
    // console.log('this.$route == ',this.$route)
    // console.log('this.$route.query == ',this.$route.query)
    // console.log('this.$route.params == ',this.$route.params)
    // console.log('this.$route.path == ',this.$route.path)
    let route = this.$route;
    resolve(route);
  });
};

/** 跳转路由
 * @author 猫吃鱼
 * @param {Object} opt 参数
 */
 function myRouterPush(opt) {
  //  https://router.vuejs.org/zh/api/#router-构建选项
  //  路由跳转配置
  //  router.push({
  //   path: string,
  //   component?: Component,
  //   name?: string, // 命名路由
  //   components?: { [name: string]: Component }, // 命名视图组件
  //   redirect?: string | Location | Function,
  //   props?: boolean | Object | Function,
  //   alias?: string | Array<string>,
  //   children?: Array<RouteConfig>, // 嵌套路由
  //   beforeEnter?: (to: Route, from: Route, next: Function) => void,
  //   meta?: any,

  //   // 2.6.0+
  //   caseSensitive?: boolean, // 匹配规则是否大小写敏感？(默认值：false)
  //   pathToRegexpOptions?: Object // 编译正则的选项
  // })


  return new Promise((resolve,reject) => {
    // console.log('router this == ',this)
    // console.log('this.$router == ',this.$router)
    this.$router.push(opt);
    resolve();
  });
};


/** 设置本地缓存
 * @author 猫吃鱼
 * @param {Object} opt 参数
 */
function mySetStorage(opt) {
  // console.log('设置本地缓存 opt == ', opt);
  console.log('设置本地缓存 opt.name == ', opt.name);
  // console.log('设置本地缓存 opt.value == ', opt.value);
  // let obj = {
  //   name:'',
  //   value:'',
  //   expires:"",
  //   startTime: new Date().getTime()//记录何时将值存入缓存，毫秒级
  // }
  return new Storage().setItem(opt);
}

/** 获取本地缓存
 * @author 猫吃鱼
 * @param {String} name 参数
 */
 function myGetStorage(name) {
  return new Storage().getItem(name);
}

/** 删除本地缓存
 * @author 猫吃鱼
 * @param {String} name 参数
 */
 function myRemoveStorage(name) {
  return new Storage().removeItem(name);
}

/** 设置cookie本地缓存
 * @author 猫吃鱼
 * @param {String} key 设置的cookie的键
 * @param {String} value 设置的cookie的值
 * @param {Number} seconds 设置cookie在多少秒之后失效 - 单位：秒
 * @param {String} path 设置cookie的路径 - 默认是 / 根目录
 */
function mySetCookie(key, value, seconds, path) {
  return new Cookie().setCookie(key, value, seconds, path);
}

/** 获取cookie本地缓存
 * @author 猫吃鱼
 * @param {String} key 参数
 */
 function myGetCookie(key) {
  return new Cookie().getCookie(key);
}

/** 删除cookie本地缓存
 * @author 猫吃鱼
 * @param {String} key 参数
 */
 function myRemoveCookie(key,path) {
  return new Cookie().removeCookie(key,path);
}

/** 设置Session本地缓存
 * @author 猫吃鱼
 * @param {String} key 设置的Session的键
 * @param {String} value 设置的Session的值
 */
 function mySetSession(key, value) {
  return new Session().setSession(key, value);
}

/** 获取Session本地缓存
 * @author 猫吃鱼
 * @param {String} key 参数
 */
 function myGetSession(key) {
  return new Session().getSession(key);
}

/** 删除Session本地缓存
 * @author 猫吃鱼
 * @param {String} key 参数
 */
 function myRemoveSession(key) {
  return new Session().removeSession(key);
}

/** 获取支付方式
 * @author 猫吃鱼
 */
function myGetPayMode() {
  return new Promise((resolve,reject) => {
    this.myRequest({
      method: 'post',
      url: '/api/buy/paymentlist',
    }).then(res => {
      console.log('获取支付方式 res == ',res);
      let payment_id = res.data.data[0].id;
      resolve(payment_id);
    }).catch(err => {
      console.log('获取支付方式 err == ',err);
      reject();
    })
  });
}

/** 获取支付方式-权威
 * @author 猫吃鱼
 */
 function myGetPayModeAuthority(opt) {
  opt = opt || {
    type: 0
  };
  return new Promise((resolve,reject) => {
    this.myRequest({
      method: 'post',
      url: '/api/buy/paymentlist',
      data: {
        type: opt.type,
      }
    }).then(res => {
      console.log('获取支付方式 res == ', res)
      // let resData = res.data.data;
      resolve(res);
    }).catch(err => {
      console.log('获取支付方式 err == ',err);
      // reject();
    })
  });
}

/** 获取支付方式-权威 Two
 * @author 猫吃鱼
 */
 function myGetPayModeAuthorityTwo(opt) {
  opt = opt || {
    type: 0
  };
  return new Promise((resolve,reject) => {
    this.myRequest({
      method: 'post',
      url: '/api/buy/paymentlistv2',
      data: {
        type: opt.type,
      }
    }).then(res => {
      console.log('获取支付方式 res == ', res)
      // let resData = res.data.data;
      resolve(res);
    }).catch(err => {
      console.log('获取支付方式 err == ',err);
      // reject();
    })
  });
}

/** 信息提示
 * @author 猫吃鱼
 * @param {Object} opt 参数
 */
function myMessage(opt) {
  let default_opt = {
    showClose: true,
    type: 'error',
    message: '发生错误，请刷新！',
    offset: 80, // 距离顶部偏移量 默认 20
  };
  Object.assign(default_opt,opt);

  return this.$message(default_opt);
}

/** 循环请求
 * @author 猫吃鱼
 * @param {Object} opt 参数
 * @param {Function} callback 参数
 */
function myLoopRequest(opt,callback){
  const _this = this;
  opt = opt || {};
  let default_opt = {
    // time: 6000 / 100, // 定时,毫秒数 默认60秒 6000 / 100 = 60
    // time: -1, // 定时,毫秒数 默认60秒 6000 / 100 = 60
    time: 1000 * 60 * 15, // 定时,毫秒数 默认 15 分钟

    id: '', //	是	string	订单id
    type: '', // 	是	string	类型【vip_order：vip订单，top_order：置顶订单】
  };
  // 如果设置了时间，则换算时间
  if(opt.time) {
    opt.time = opt.time / 1000;
  }
  Object.assign(default_opt,opt);

  console.log('循环请求-参数 == ',default_opt)

  // 声明结果
  let result = {
    timer: null, // 定时器
    status: 0, // 0/正常/执行中 1/成功 2/失败 3/超时
    message: '正常执行中',
    data: {},
  };
  // 定时器
  result.timer = setInterval(function(){
    if(default_opt.time != -1) {
      default_opt.time --;
      console.log('循环请求 time == ',default_opt.time)
      // 请求超时
      if(default_opt.time <= 0) {
        clearInterval(result.timer)
        result.status = 3;
        result.message = '支付超时';
        result.data = {};
        // 回调函数
        if(callback) {
          callback(result)
        }
        return;
      }
    }

    // 发起请求
    _this.myRequest({
      url: '/api/order/orderpaystatus',
      data: {
        id: default_opt.id,
        type: default_opt.type,
      }
    },{is_loading: false}).then((res) => {
      // clearInterval(result.timer)
      console.log('循环请求 res == ',res)
      result.status = 1;
      result.message = '成功';
      result.data = res.data.data;
      // 回调函数
      if(callback) {
        callback(result)
      }
    }).catch((err) => {
      clearInterval(result.timer)
      console.log('循环请求 res == ',err)
      result.status = 2;
      result.message = '失败';
      result.data = err;
      // 回调函数
      if(callback) {
        callback(result)
      }
    })
  },1000);

  // 回调函数
  if(callback) {
    callback(result)
  }

  return result;
}


/**
 * 封装加载
 * @param {Object} opt
 * @returns
 */
function myLoading(opt){

  let default_opt = {
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)',
    timing: 2000,
  };

  Object.assign(default_opt,opt);

  let loading = this.$loading(default_opt);
  // setTimeout(() => {
  //   loading.close();
  // }, default_opt.timing);
  return loading;
}

/**
 * 存储输入历史
 * @param {Object} opt
 */
function mySetStorageInputHistory(opt) {

  // 获取存储 key 名
  let { input_history } = this.STORAGE;

  // 默认存储
  let default_opt = {
    name: input_history['child_name']['test'],
    value: null,
  };

  // 修改默认存储指向
  default_opt = Object.assign(default_opt,opt);

  // 获取存储对象的数据，如果有的话
  let befoer_data = this.myGetStorage(input_history['stroage_name']) || {};
  console.log('存储输入历史 befoer_data == ',befoer_data)

  // 生成新的存储对象
  let result = {};
  result[default_opt.name] = default_opt.value;

  // 将新的储存的对象合并到就数据里，没有旧的则作为新的储存
  let after_data = Object.assign(befoer_data,result);
  console.log('存储输入历史 after_data == ',after_data)

  // 执行储存函数
  this.mySetStorage({
    name: input_history['stroage_name'],
    value: after_data,
    // expires: 2000, // 时间：存储时长，毫秒数
  });
};

/**
 * 获取-存储的输入历史
 * @param {String} name
 * @param {Function} callback
 */
 function myGetStorageInputHistory(name,callback) {

  // 获取存储 key 名
  let { input_history } = this.STORAGE;

  // 默认 key 名
  name = name || input_history['child_name']['test'];

  let original_value = this.myGetStorage(input_history['stroage_name']);
  console.log('获取-存储的输入历史 original_value == ',original_value)
  let find_data = original_value[name];
  let result = {
    original: original_value,
    target: find_data,
  }

  console.log('获取-存储的输入历史 result == ',result)
  if(callback) {
    callback(result);
  }
  return result;
};


/** 保险-请求函数封装
 * @author 猫吃鱼
 * @param {Object} config 参数
 */
function myInsuranceRequest(config = {}) {
  let { requestConfig, accountConfig } = this.GLOBAL.insurance;

  // 合并路由
  Object.assign(config,{url: requestConfig.url + config.url,})

  return this.myRequest(config);
};

/**
 * 保单支付
 * @param {String,Number} id
 */
function myInsurancePayRequest(id, type = 1, inherit) {
  return new Promise((resolve,reject) => {
    this.myRequest({
      url: '/api/insurance/pay',
      data: {
        id: id,
        payment_id: type, //	否	string	支付方式id【1：微信支付，1000：畅捷支付】（为空时后台默认为微信支付）
        parent_wallet: inherit,
      }
    }).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  })
}

/**
 * 保单下载
 * @param {String,Number} order
 */
function myInsuranceOrderDownloadRequest(order) {
  // loginname	用户账号	String	Y	用户帐号
  // loginpwd	用户密码	String	Y	用户密码
  // filetype 	保单格式	int	Y	0：pdf;1:jpg （jpg如果存在多张返回则为zip文件）
  // {orderNo}	订单No	String	Y	投保成功返回的订单NO
  let { accountConfig } = this.GLOBAL.insurance;
  let {account = 'API测试账户', passwrod = 'aaa@123' } = accountConfig;
  return new Promise((resolve,reject) => {
    this.myInsuranceRequest({
      responseType: 'json', // default
      url: '/downorderploxyfile',
      data: {
        'loginname': account,
        'loginpwd': passwrod,
        'filetype': 1,
        'orderNo': order,
      }
    }).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  })
};


/**
 * 获取当前设备
 * @param {Function} callback
 */
function myGetCurrentFacility(callback){
  var UserClient = navigator.userAgent.toLowerCase();
  console.log('获取当前设备 UserClient == ',UserClient)
  let IsIPad = UserClient.match(/ipad/i) == "ipad";
  let IsIphoneOs = UserClient.match(/iphone os/i) == "iphone os";
  let IsMidp = UserClient.match(/midp/i) == "midp";
  let IsUc7 = UserClient.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
  let IsUc = UserClient.match(/ucweb/i) == "ucweb";
  let IsAndroid = UserClient.match(/android/i) == "android";
  let IsCE = UserClient.match(/windows ce/i) == "windows ce";
  let IsWM = UserClient.match(/windows mobile/i) == "windows mobile";
  // 结果
  let result = {
    facility: '',
    is_ios: false,
    ios_grade: 0,
    model_name: '',
  };

  if(IsIPad || IsIphoneOs || IsMidp || IsUc7 || IsUc || IsAndroid || IsCE || IsWM){
    result.facility = 'mobile';
  }else{
    result.facility = 'pc';
  }


  // 当然，实现功能时要简化代码和对iOS系统更精准的判断时，可以在上述代码之前加上下面这一行判断：
  let isIOS = /^(iPhone|iPad|iPod|Pike)/ig.test(navigator.platform) || !!UserClient.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  if(isIOS) {
    result.is_ios = true;
    let mobile_model = getMobileModelFun();
    let {model, grade} = mobile_model;
    result.model_name = model;
    result.ios_grade = grade;
  }

  // // 设置本地缓存
  // let { browse_equipment } = this.STORAGE;
  // this.mySetSession(browse_equipment,result)


  if(callback) {
    callback(result);
  }

  return result;
}

/**
 * 设置/获取-邀请用户id
 */
function mySetAndGetInviteUserId(routerObj = {},callback) {
    console.log('设置/获取-邀请用户id routerObj == ',routerObj)
    if(routerObj && routerObj.query) { // 如果有id传入则设置缓存
    let { invite_user_id } = routerObj.query;
    if(invite_user_id) {
      // 储存缓存-邀请用户id
      this.mySetStorage({
        name: this.STORAGE.invite_user_id,
        value: invite_user_id,
        expires: 1000 * 60 * 60 * 24 * 3, // 时间：存储时长，毫秒数 默认：3天
        // expires: 1000 * 30, // 时间：存储时长，毫秒数 默认：30秒 【测试】
      });
    }
  } else {
    // 获取缓存-邀请用户id
    let invite_user_id = this.myGetStorage(this.STORAGE.invite_user_id) || '';
    console.log('设置/获取-邀请用户id invite_user_id == ',invite_user_id)
    if(callback) {
      callback(invite_user_id);
    }
    return invite_user_id;
  }
  return '';
}

/**
 * 第三方-保存文件到本地
 * @param {Url} url 文件地址
 */
 function thirdDownloadFile(url,name) {
  name = name || cerateFileNameFun(); // 生成文件名称
  if(url) {
    download(url,name)
  }
}

/**
 * 保存文件到本地
 * @param {Url} url 文件地址
 */
function myDownloadFile(url,name) {
  name = name || cerateFileNameFun(); // 生成文件名称
  if(url) {
    saveAs(url,name)
  }
}

/**
 * 保存图片到本地
 * @param {Url} src 文件地址
 */
function myDownloadIamge(src) {
  console.log('保存图片到本地 src == ',src);
  const a = document.createElement('a')
  const urlArr = src.split('/')
  const name = urlArr[urlArr.length - 1]
  // console.log('name == ',name);
  a.download = name
  a.style.display = 'none'
  const image = new Image()
  image.crossOrigin = "Anonymous"
  image.src = src + '?v=' + Math.random()
  image.onload = () => {
    const base64 = getBase64Image(image)
    let url = base64;
    saveAs(url,name)
    // downloadFile(url,name);
    // a.href = base64
    // document.body.appendChild(a)
    // a.click()

    // document.body.removeChild(a)
    console.log('保存图片到本地 end');
  }
  console.log('保存图片到本地 image == ',image);
  const getBase64Image = (img) => {
    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, img.width, img.height);
    const ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
    const dataURL = canvas.toDataURL("image/" + ext);
    return dataURL;
  }
}


/**
 * 获取账号信息
 */
function myGetUserInfoFun (){
  return new Promise((resolve,reject) => {
    this.myRequest({
      url: '/api/user/userinfostatus',
    }).then((res) => {
      console.log('获取账号信息 res == ',res)
      let resData = res.data.data;
      let { son_account } = resData;
      let flag = son_account > 0;
      resolve(flag)
    })
  })
}

 /**
  * 获取图片大小
  */
function myGetImgSize(url) {
  console.log('获取图片大小 url ==',url)
  return new Promise((resolve,reject) => {

    var imgReady = (function () {
      var list = [], intervalId = null,
      // 用来执行队列
      tick = function () {
        var i = 0;
        for (; i < list.length; i++) {
          list[i].end ? list.splice(i--, 1) : list[i]();
        };
        !list.length && stop();
      },
      // 停止所有定时器队列
      stop = function () {
        clearInterval(intervalId);
        intervalId = null;
      };
      return function (url, ready, load, error) {
        var onready, width, height, newWidth, newHeight,
        img = new Image();
        img.src = url;
        // 如果图片被缓存，则直接返回缓存数据
        if (img.complete) {
          ready.call(img);
          load && load.call(img);
          return;
        };
        width = img.width;
        height = img.height;
        // 加载错误后的事件
        img.onerror = function () {
          error && error.call(img);
          onready.end = true;
          img = img.onload = img.onerror = null;
        };
        // 图片尺寸就绪
        onready = function () {
          newWidth = img.width;
          newHeight = img.height;
          if (newWidth !== width || newHeight !== height ||newWidth * newHeight > 1024) {
          // 如果图片已经在其他地方加载可使用面积检测
            ready.call(img);
            onready.end = true;
          };
        };
        onready();
        // 完全加载完毕的事件
        img.onload = function () {
          // onload在定时器时间差范围内可能比onready快
          // 这里进行检查并保证onready优先执行
          !onready.end && onready();
          load && load.call(img);
          // IE gif动画会循环执行onload，置空onload即可
          img = img.onload = img.onerror = null;
        };
        // 加入队列中定期执行
        if (!onready.end) {
          list.push(onready);
          // 无论何时只允许出现一个定时器，减少浏览器性能损耗
          if (intervalId === null) intervalId = setInterval(tick, 40);
        };
      };
    })();


    imgReady(url, function () {
      alert('size ready: width=' + this.width + '; height=' + this.height);
      resolve({width: this.width, height: this.height});
    });
  })
}

// 全局函数表
const fun_form = {
  // test0,
  test1, // 【推荐】
  test2, // 【推荐】
  test() {
    console.log('全局函数 test this',this)
  },
  myRequest, // 请求函数封装
  myLoginInfoRequest, // 登录信息请求
  mylogOutRequestFun, // 退出登录请求
  myGetOssSignature, // 获取oss签名
  myUploadFileToServers, // 上传文件到服务器
  myGetRouter, // 获取当前路由
  myRouterPush, // 跳转路由
  mySetStorage, // 设置本地缓存
  myGetStorage, // 获取本地缓存
  myRemoveStorage, // 删除本地缓存
  mySetCookie,// 设置cookie本地缓存
  myGetCookie, // 获取cookie本地缓存
  myRemoveCookie, // 删除cookie本地缓存
  mySetSession,// 设置Session本地缓存
  myGetSession, // 获取Session本地缓存
  myRemoveSession, // 删除Session本地缓存
  myGetPayMode, // 获取支付方式
  myGetPayModeAuthority, // 获取支付方式-权威
  myGetPayModeAuthorityTwo, // 获取支付方式-权威 two
  myMessage, // 信息提示
  myLoopRequest, // 循环请求
  myLoading, // 加载
  mySetStorageInputHistory, // 存储输入历史
  myGetStorageInputHistory, // 获取-存储输入历史
  myChecktool, // 校验工具包
  myInsurancePayRequest, // 保单支付
  myInsuranceRequest, // 保险请求封装 【外部链接： http://】
  myInsuranceOrderDownloadRequest, // 保单下载 【外部链接： http://】
  myGetCurrentFacility, // 获取当前设备
  mySetAndGetInviteUserId, // 设置/获取-邀请用户id
  myDownloadFile, // 保存文件到本地
  myDownloadIamge, // 保存图片到本地
  // thirdDownloadFile, // 第三方-保存文件到本地
  myGetUserInfoFun,
  myGetImgSize, // 获取图片大小
}

console.log('全局函数表 == ',fun_form)


// console.log(fun_form)
export default {
  install: function(Vue,options) {
    // Vue.prototype['test'] = function() {
    //   console.log('测试函数名重复错误')
    // };
    for(let key in fun_form) {
      if(Vue.prototype[key]) throw new Error(`The vue already includes "${key}" functions`); // 函数名已存在
      if(fun_form.hasOwnProperty(key)) {
        Vue.prototype[key] = fun_form[key];
      }
    }
  }
}
