let MobileDetect = require('mobile-detect');



/** 获取oss签名
 * @author 猫吃鱼
 * @param {Object} file // 要上传的文件
 */
 function myGetOssSignatureFun(_this,file) {
  console.log('获取oss签名 file == ',file)
  // 判断参数是否为空
  // if(!file || !file.raw) throw new Error('Error: The file is empty!');

  // 文件类型
  // let { type } = file.raw;
  // if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(type)) {
  //   this.$message({
  //     message: '图片类型要求：jpeg、jpg、png',
  //     type: "error"
  //   });
  //   return false
  // }

  let suffix = getSuffixFun(file);  // 获取文件后缀
  let fileName = cerateFileNameFun(); // 生成文件名称

  console.log('_this == ',_this)

  // 返回结果
    return new Promise((resolve,reject) => {
      // 发送请求
      _this.myRequest({
        url: '/api/alioss/aliosspolicy'
      }).then(res => {
        // 请求返回数据示例
        // accessid: "LTAI4GAwEUafzNMJ5CUJXgV6"
        // callback: "eyJjYWxsYmFja1VybCI6Imh0dHBzOlwvXC90ZXN0LmJhaXR1YW5sZS5jb21cLz9zPVwvYXBpXC9hbGlvc3NcL2NhbGxiYWNrIiwiY2FsbGJhY2tCb2R5IjoiZmlsZW5hbWU9JHtvYmplY3R9JnNpemU9JHtzaXplfSZtaW1lVHlwZT0ke21pbWVUeXBlfSZoZWlnaHQ9JHtpbWFnZUluZm8uaGVpZ2h0fSZ3aWR0aD0ke2ltYWdlSW5mby53aWR0aH0iLCJjYWxsYmFja0JvZHlUeXBlIjoiYXBwbGljYXRpb25cL3gtd3d3LWZvcm0tdXJsZW5jb2RlZCJ9"
        // dir: "static/upload/images/other/2021/10/14/"
        // expire: 1634191603
        // host: "https://bdt-file.oss-cn-beijing.aliyuncs.com"
        // policy: "eyJleHBpcmF0aW9uIjoiMjAyMS0xMC0xNFQxNDowNjo0M1oiLCJjb25kaXRpb25zIjpbWyJjb250ZW50LWxlbmd0aC1yYW5nZSIsMCwxMDQ4NTc2MDAwXSxbInN0YXJ0cy13aXRoIiwiJGtleSIsInN0YXRpY1wvdXBsb2FkXC9pbWFnZXNcL290aGVyXC8yMDIxXC8xMFwvMTRcLyJdXX0="
        // signature: "REd+OTFi+2K+Qp3uRKiXZFBBpsM="
        let resData = res.data.data;
        let { accessid, callback, dir, expire, host, policy, signature } = resData;
        let key = dir + fileName + suffix;
        let result = {
          "path": host + '/' + key,
          "key": key,
          "policy": policy,
          "OSSAccessKeyId": accessid,
          "signature": signature,
        };

        // 成功
        resolve(result);
      }).catch(() => {
        // 失败
        reject();
      })
    })
}
/** 获取文件后缀
 * @author 猫吃鱼
 * @param {Object} file // 文件
 */
function getSuffixFun(file) {
  console.log('获取文件后缀 file == ',file)
  let { type } = file.raw || file;
  let flag = type.indexOf('/');
	let suffix = type.slice(flag,type.length);
  let result = suffix.replace('/','.');
  console.log('result == ',result)
  if(result == '.vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    result = '.xlsx';
  }
  return result;
}


/**
 * 校验文件类型
 */
function verifyFileTypeFun(str) {
  console.log('校验文件类型 str ==', str)
  let suffix = str.slice(str.indexOf('.') + 1);
  suffix = suffix.toUpperCase(); // 转换大写
  suffix = suffix.toLowerCase(); // 转换小写
  console.log('校验文件类型 suffix ==', suffix)
  if(suffix.indexOf('.') == -1) {
    return suffix;
  }
  return verifyFileTypeFun(suffix);
}

/** 生成文件名称
 * @author 猫吃鱼
 */
function cerateFileNameFun() {
  // const saveFileName = parseInt(new Date().getTime()/1000)+Math.round(Math.random()*999999+100000);
  return parseInt(new Date().getTime()/1000)+Math.round(Math.random()*999999+100000);
}


/** 防抖函数 (debounce)
 * @author 猫吃鱼
 * @param {Function} fn // 要执行的函数
 * @param {Object} delay // 时间毫秒
 */
function debounce(fn, delay) {
  var timer;
  var gapTime = delay || 1000;//间隔时间，如果delay不传，则默认1000ms
  return function () {
    clearTimeout(timer);
    var context = this;
    var args = arguments;//保存此处的arguments，因为setTimeout是全局的，arguments不是防抖函数需要的。
    timer = setTimeout(function () {
      fn.call(context, args);
    }, gapTime);
  };
}

/** 节流函数 (throttle)
 * @author 猫吃鱼
 * @param {Function} fn 要执行的函数
 * @param {Nunber} delay 时间毫秒
 */
function throttle(fn, delay) {
  var enterTime = 0;//触发的时间
  var gapTime = delay || 300;//间隔时间，如果delay不传，则默认300ms
  return function () {
    var context = this;
    var backTime = new Date();//第一次函数return即触发的时间
    if (backTime - enterTime > gapTime) {
      fn.call(context, arguments);
      enterTime = backTime;//赋值给第一次触发的时间，这样就保存了第二次触发的时间
    }
  };
}



/**
 * 初始化页面样式
 */
 function initPageStyleFun() {
  let html = document.querySelector('html');
  let body = document.querySelector('body');
  console.log('html == ',html);
  console.log('body == ',body);
  html.setAttribute('style', 'height: 100%; overflow: hidden;')
  body.setAttribute('style', 'height: 100%; overflow: hidden;')
}


/** 深拷贝
 * @author 猫吃鱼
 * @param {Object,Array} target // 目标参数
 */
 function deepClone(target) {
  // 定义一个变量
  let result;
  // 如果当前需要深拷贝的是一个对象的话
  if (typeof target === 'object') {
    // 如果是一个数组的话
    if (Array.isArray(target)) {
        result = []; // 将result赋值为一个数组，并且执行遍历
        for (let i in target) {
            // 递归克隆数组中的每一项
            result.push(deepClone(target[i]))
        }
      // 判断如果当前的值是null的话；直接赋值为null
    } else if(target===null) {
        result = null;
      // 判断如果当前的值是一个RegExp对象的话，直接赋值
    } else if(target.constructor===RegExp){
        result = target;
    }else {
      // 否则是普通对象，直接for in循环，递归赋值对象的所有值
        result = {};
        for (let i in target) {
            result[i] = deepClone(target[i]);
        }
    }
    // 如果不是对象的话，就是基本数据类型，那么直接赋值
    } else {
        result = target;
    }
   // 返回最终结果
  return result;
}

/**
 * @author 猫吃鱼
 * @param {Object} obj // 参数对象 [start,[end]]
 * @param {Function} callback // 要执行的函数
 */
 function countDownFun(data_str,callback) {
   console.log('data_str == ',data_str)
  var interval;
  // // 获取当前时间
  // var date = new Date();
  // var start = date.getTime();
  // 获取结束时间
  var endDate = new Date(data_str);
  var end = endDate.getTime();
  // 算出总需要倒计时的时间差
  // var active_time = end - start;
  clearInterval(interval);
  interval = setInterval(function() {
    // 获取当前时间
    let now = Date.now();
    let leftTime =  end - now;
    // 定义变量 d,h,m,s保存倒计时的时间
    var d,h,m,s;
    if (leftTime > 0) {
      d = Math.floor(leftTime/1000/60/60/24);
      h = Math.floor(leftTime/1000/60/60%24);
      m = Math.floor(leftTime/1000/60%60);
      s = Math.floor(leftTime/1000%60);
      if(callback) {
        callback({d,h,m,s})
      }
    } else {
      clearInterval(interval);
      if(callback) {
        callback({d:'00',h:'00',m:'00',s:'00'})
      }
    }
  },1000);
  return interval;
}



/**
 * DataURL转Blob对象
 * @param {*} dataurl
 * @returns
 */
function dataURLToBlob(dataurl){
	var arr = dataurl.split(',');
	var mime = arr[0].match(/:(.*?);/)[1];
	var bstr = atob(arr[1]);
	var n = bstr.length;
	var u8arr = new Uint8Array(n);
	while(n--){
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new Blob([u8arr], {type:mime});
}

/**
 * 将blob转换为file
 * @param {Blob} theBlob
 * @param {String} fileName
 * 调用：var file = blobToFile(blob, imgName);
 */
function blobToFile(theBlob, url, fileName){
    if(url) {
      theBlob.url = url;
    }
    fileName = fileName || cerateFileNameFun(); // 生成文件名称
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
};

/**
 * canvas转dataURL：canvas对象、转换格式、图像品质
 * @param {Object} canvas contentText
 * @param {String} format image/jpeg
 * @param {Float} quality 1.1
 * @returns
 */
function canvasToDataURL(canvas, format, quality){
	return canvas.toDataURL(format||'image/jpeg', quality||1.0);
}

/**
 * File/Blob对象转DataURL
 * @param {Object} obj
 * @param {Function} cb
 */
function fileOrBlobToDataURL(obj, cb){
	var a = new FileReader();
	a.readAsDataURL(obj);
	a.onload = function (e){
		cb(e.target.result);
	};
}

/**
 * 将Blob转image
 * @param {blob} blob
 * @param {Function} cb
 */
function blobToImage(blob, cb){
	fileOrBlobToDataURL(blob, function (dataurl){
		var img = new Image();
		img.src = dataurl;
		cb(img,dataurl);
	});
}

/**
 * image转Blob
 * @param {String} src
 * @param {Function} cb
 */
function imageToBlob(src, cb){
	imageToCanvas(src, function (canvas){
		cb(dataURLToBlob(canvasToDataURL(canvas)));
	});
}

/**
 * image转canvas：图片地址
 * @param {String} src
 * @param {Function} cb
 */
function imageToCanvas(src, cb){
	var canvas = document.createElement('CANVAS');
	var ctx = canvas.getContext('2d');
	var img = new Image();
	img.src = src;
	img.onload = function (){
		canvas.width = img.width;
		canvas.height = img.height;
		ctx.drawImage(img, 0, 0);
		cb(canvas);
	};
}

/**
 * 获取文件的Base64
 * @param file      {File}      文件
 * @param callback  {Function}  回调函数，参数为获取到的base64
 */
 function fileToBase64(file, callback) {
  const fileReader = new FileReader()
  fileReader.readAsDataURL(file)
  fileReader.onload = function (e) {
    // callback(e.target.result)
    callback(this.result)
  }
}

/**
 * Base64转File
 * @param dataURL   {String}  base64
 * @param fileName	{String}  文件名
 * @param mimeType	{String}  [可选]文件类型，默认为base64中的类型
 * @returns {File}
 */
 function base64ToFile(base64, fileName, mimeType = null) {
    fileName = fileName || cerateFileNameFun(); // 生成文件名称
    const arr = dataURL.split(','),
  	defaultMimeType = arr[0].match(/:(.*?);/)[1],
  	bStr = atob(arr[1]),
  	u8arr = new Uint8Array(n)
  let n = bStr.length
  while (n--) {
    u8arr[n] = bStr.charCodeAt(n)
  }
  return new File([u8arr], fileName, {type: mimeType || defaultMimeType})
}

/**
 * 解析日期对象
 * @param {Date} date
 * @param {Function} cb
 * @param {Boolean} is_vt // 是否补全
 */
function analysisDateObject(date,callback,is_vt) {

  // 现在的日期
  const nd_cDate = new Date();
  const nd_year = nd_cDate.getFullYear(); // 年
  const nd_month = nd_cDate.getMonth() + 1; // 月
  const nd_days = nd_cDate.getDate(); // 天
  const nd_hours = nd_cDate.getHours(); // 时
  const nd_minutes = nd_cDate.getMinutes(); // 分
  const nd_seconds = nd_cDate.getSeconds(); // 秒
  const nd_time = nd_cDate.getTime(); // 时间戳 10位数

  // 现在的日期-对象
  let nowaday = {
    date: nd_cDate,
    year: nd_year,
    month: nd_month,
    days: nd_days,
    hours: nd_hours,
    minutes: nd_minutes,
    seconds: nd_seconds,
    time: nd_time,
  }


  // 选择日期
  let t_cDate = new Date();
  if(Boolean(date)) { // 兼容 IOS; IOS 日期格式为 yyy/MM/dd 如：'2021/12/30 00:00:00
    if(String(date).indexOf('-') != -1) {
      date = date.replace(/-/g,"/");
    }
    t_cDate = new Date(date);
  }
  const t_year = t_cDate.getFullYear(); // 年
  const t_month = t_cDate.getMonth() + 1; // 月
  const t_days = t_cDate.getDate(); // 天
  const t_hours = t_cDate.getHours(); // 时
  const t_minutes = t_cDate.getMinutes(); // 分
  const t_seconds = t_cDate.getSeconds(); // 秒
  const t_time = t_cDate.getTime(); // 时间戳 10位数

  // 选择日期-对象
  let target = {
    date: t_cDate,
    year: t_year,
    month: t_month,
    days: t_days,
    hours: t_hours,
    minutes: t_minutes,
    seconds: t_seconds,
    time: t_time,
  }

  // 补全-补0
  if(is_vt) {
    for(let nd_key in nowaday) {
      if(nowaday.hasOwnProperty(nd_key)) {
        nowaday[nd_key] = formatNumber(nowaday[nd_key]);
      }
    }
    for(let key in target) {
      if(target.hasOwnProperty(key)) {
        target[key] = formatNumber(target[key]);
      }
    }
  }

  // 结果
  let result = {
    nowaday,
    target,
  };

  console.log('解析日期对象 result == ',result)

  if(callback) {
    callback(result);
  }
  return result;
}
/**
 * 长度补全
 * @param {String,Number}} n
 */
const formatNumber = n => {
  n = n.toString()
  return n[1] ? n : `0${n}`
}

/**
 * 将时间戳转换成日期格式
 * @param {*} timestamp
 * @returns
 */
function timestampToTime(timestamp) {
  var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var Y = date.getFullYear() + '-';
  var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
  var D = date.getDate() + ' ';
  var h = date.getHours() + ':';
  var m = date.getMinutes() + ':';
  var s = date.getSeconds();
  return Y+M+D+h+m+s;
}

/**
 * 判断是否是微信浏览器的函数
 */
function isWeiXinBrowser(){
  //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
  var ua = window.navigator.userAgent.toLowerCase();
  //通过正则表达式匹配ua中是否含有MicroMessenger字符串
  if(ua.match(/MicroMessenger/i) == 'micromessenger'){
    return true;
  }else{
    return false;
  }
}

/**
 * 获取手机型号
 */
function getMobileModelFun(userAgent) {
  userAgent = userAgent || navigator.userAgent;//获取userAgent信息
  console.log('获取手机型号 userAgent == ',userAgent)
  var md = new MobileDetect(userAgent);
  var os = md.os();//获取系统
  // model:手机型号
  var model = "";
  var grade = "";
  if (os == "iOS") {//ios系统的处理
    model = md.mobile();
    if (model == "iPhone") {
      let iphoneArr = [
        ['428,926,3', "A14", "iPhone 12 Pro Max", 3],
        ['390,844,3', "A14", "iPhone 12 Pro", 3],
        ['360,780,3', "A14", "iPhone 12", 3],
        ['320,568,2', "A13", "iPhone SE（2代）", 1],
        ['414,896,3', "A13", "iPhone 11 Pro Max", 3],
        ['375,812,3', "A13", "iPhone 11 Pro", 3],
        ['414,896,2', "A13", "iPhone 11", 3],
        ['414,896,3', "A12", "iPhone XS Max", 3],
        ['375,812,3', "A12", "iPhone XS", 3],
        ['414,896,2', "A12", "iPhone XR", 3],
        ['375,812,3', "A11", "iPhone X", 3],
        ['414,736,3', "A11", "iPhone 8 Plus", 1],
        ['414,736,3', "A10", "iPhone 7 Plus", 1],
        ['414,736,3', "A9", "iPhone 6S Plus", 1],
        ['414,736,3', "A8", "iPhone 6 Plus", 1],
        ['375,667,2', "A11", "iPhone 8", 1],
        ['376,667,2', "A11", "iPhone 8", 1],
        ['376,667,2', "A10", "iPhone 7", 1],
        ['376,667,2', "A9", "iPhone 6S", 1],
        ['376,667,2', "A8", "iPhone 6", 1],
        ['320,568,2', "A9", "iPhone SE（1代）", 1],
        ['320,568,2', "A7", "iPhone 5S", 1],
        ['320,568,2', "A6", "iPhone 5", 1]
      ]

      // 获取GPU信息
      var canvas = document.createElement('canvas'),
        gl = canvas.getContext('experimental-webgl'),
        debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
      let iphoneGPU = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
      // alert(iphoneGPU)
      let width = window.screen.width;
      let height = window.screen.height;
      let devicePixelRatio = window.devicePixelRatio;
      let baseInfo = width + ',' + height + ',' + devicePixelRatio;
      let flag = false;
      for(let i in iphoneArr){
        for(let j in iphoneArr[i]){
          console.log(iphoneArr[i][0], baseInfo , iphoneGPU,iphoneArr[i][1])
          // if(iphoneArr[i][0] == baseInfo && iphoneGPU.indexOf(iphoneArr[i][1]) != -1){
          //   model = iphoneArr[i][2];
          //   grade = iphoneArr[i][3];
          //   flag = true;
          //   break;
          // }
          if(iphoneArr[i][0] == baseInfo){
            model = iphoneArr[i][2];
            grade = iphoneArr[i][3];
            flag = true;
            break;
          }
        }
        if(flag){
          break;
        }
      }
      if(!flag){
        if((width == 375 && height == 812) || (width == 414 && height == 896)){
          model = 'iPhone X(11)'
        }else if(width == 360 && height == 780){
          model = 'iPhone 12'
        }else if(width == 390 && height == 840){
          model = 'iPhone 12 Pro'
        }else if(width == 428 && height == 926){
          model = 'iPhone 12 Pro Max'
        } else {
          grade = 3;
        }
      }
      console.log(model);
    }else{
      model = "iPad"
    }
  } else if (os == "AndroidOS") {//Android系统的处理
    var j, sss = userAgent.split(";");
    for (var i = 0; i < sss.length; i++) {
      if (sss[i].indexOf("Build/") > 0) {
        j = i;
        break;
      }
    }
    if (j > -1) {
      model = sss[j].substring(0, sss[j].indexOf("Build/"));
    }
  }
  console.log('model 是', model)
  console.log('grade 是', grade)

  return {model, grade};
}


/**
 * 获取地址信息
 * @returns
 */
 function getUrlInfo(url) {
  // hash	设置或返回从井号 (#) 开始的 URL（锚）。
  // host	设置或返回主机名和当前 URL 的端口号。
  // hostname	设置或返回当前 URL 的主机名。
  // href	设置或返回完整的 URL。
  // pathname	设置或返回当前 URL 的路径部分。
  // port	设置或返回当前 URL 的端口号。
  // protocol	设置或返回当前 URL 的协议。
  return { hash, host, hostname, href, pathname, port, protocol } = url || window.location;
}


module.exports = {
  myGetOssSignatureFun, // 获取oss签名
  getSuffixFun, // 获取文件后缀
  verifyFileTypeFun, // 校验文件类型-返回文件后缀
  cerateFileNameFun, // 生成文件名称
  debounce, // 防抖
  throttle, // 节流
  initPageStyleFun, // 初始化页面样式
  deepClone, // 深拷贝
  countDownFun, // 倒计时
  dataURLToBlob, // 将base64转换为blob
  blobToFile, // 将blob转换为file
  blobToImage, // 将Blob转image
  imageToBlob, // image转Blob
  fileToBase64, // file转base64
  base64ToFile, // base64转file
  analysisDateObject, // 解析日期对象
  timestampToTime, // 将时间戳转换成日期格式
  isWeiXinBrowser, // 判断是否是微信浏览器的函数
  getMobileModelFun, // 获取手机型号
  getUrlInfo, // 获取地址信息
}
