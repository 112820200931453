<template>
  <!-- <div class="container">
    <el-dialog width="300px" title="选择支付方式" center :visible.sync="mapVisible" :close-on-click-modal="false"
      :before-close="dialogBeforeCloseFun">
      <div class="select-pay-method">
        <div class="spm-title">请选择支付方式</div>
        <ul class="spm-list">
          <li class="sl-item" v-for="(item,index) in paymentConfig.options" :key="index"
            @click="choosePaymentMethodFun(index)">
            <el-button class="fake-btn">
              <div class="icon">
                <div class="icon-box">
                  <img class="fake-img" :src="item.logo" alt="item.name">
                </div>
                <span class="icon-text">{{item.name}}</span>
              </div>
            </el-button>
          </li>
        </ul>
      </div>
    </el-dialog>


    对话框
    <my-dialog :visible="myDialog.visible" :options="myDialog.options" @on-change="myDialogChangeFun" />

  </div> -->

  <div class="container">
    <el-dialog width="300px" title="选择支付方式" center :visible.sync="mapVisible" :close-on-click-modal="false"
      :before-close="dialogBeforeCloseFun">
      <div class="select-pay-method">
        <!-- <div class="spm-title">请选择支付方式</div> -->
        <div class="spm-box">
          <div class="spm-head">
            <div class="spm-box-title">支付方式：</div>
          </div>
          <ul class="spm-list">
            <li class="sl-item" v-for="(item,index) in paymentConfig.options.my" :key="index"
              @click="choosePaymentMethodFun(index,'my')">
              <el-button class="fake-btn">
                <div class="icon">
                  <div class="icon-box">
                    <img class="fake-img" :src="item.logo" :alt="item.name">
                  </div>
                  <span class="icon-text">
                    <span>{{item.name}}</span>
                    <span class="price-text" v-if="item.id != 1 && item.id != 1000">（{{item.money}}）</span>
                  </span>
                </div>
              </el-button>
            </li>
          </ul>
        </div>

        <div class="spm-box" v-if="paymentConfig.options.parent && paymentConfig.options.parent.length > 0">
          <div class="spm-head">
            <div class="spm-box-title">代付方式：</div>
          </div>
          <ul class="spm-list">
            <li class="sl-item" v-for="(item,index) in paymentConfig.options.parent" :key="index"
              @click="choosePaymentMethodFun(index,'parent')">
              <el-button class="fake-btn">
                <div class="icon">
                  <div class="icon-box">
                    <img class="fake-img" :src="item.logo" :alt="item.name">
                  </div>
                  <span class="icon-text">
                    <span>{{item.name}}</span>
                    <span class="price-text" v-if="item.id != 1 && item.id != 1000">（{{item.money}}）</span>
                  </span>
                </div>
              </el-button>
            </li>
          </ul>
        </div>
      </div>
    </el-dialog>


    <!-- 对话框 -->
    <my-dialog :visible="myDialog.visible" :options="myDialog.options" @on-change="myDialogChangeFun" />

  </div>
</template>

<script>
  export default {
    // 允许组件模板递归地调用自身
    name: 'select-pay-method',
    // 声明一组可用于组件实例中的组件
    components: {

    },
    // 一个用于从父组件接收数据的数组或对象
    props: {
      visible: {
        type: Boolean,
        default: true,
      },
      type: 0,
      options: {
        type: Object,
        default: () => {}
      }
    },
    // 该函数返回组件实例的 data 对象
    data() {
      return {
        mapOptions: Object.assign({
          price: '', // 价格
        }, this.options),
        mapVisible: this.visible,
        paymentConfig: { // 支付配置
          loading: false,
          options: [],
        },
        myDialog: { // 确认弹窗配置
          data: {},
          visible: false,
          options: {},
        },
      }
    },
    // 计算属性：
    computed: {

    },
    // 钩子函数--侦听data变动：
    watch: {
      visible: function (e) {
        console.log('选择支付方式 == ', e)
        this.mapVisible = e;
      },
      options: function (e) {
        this.mapOptions = Object.assign(this.mapOptions, e);
      }
    },
    // 在实例创建完成后被立即同步调用
    methods: {
      /**
       * 关闭弹窗前
       */
      dialogBeforeCloseFun(done) {
        done();
        console.log('关闭弹窗前 == ', this.mapVisible)
        // 告知弹窗变化
        this.informChangeFun();
      },
      /**
       * 告知弹窗变化
       */
      informChangeFun(opt) {
        let default_data = {
          visible: false,
          status: 2, // 1/正常 2/关闭 3/其他
          message: '关闭',
          data: '',
        };
        let result = Object.assign(default_data, opt);
        this.$emit('on-change', result)
      },
      /**
       * 选择支付方式
       */
      choosePaymentMethodFun(index, name) {

        console.log('选择支付方式 this.paymentConfig == ', this.paymentConfig)
        console.log('选择支付方式 index == ', index)
        console.log('选择支付方式 name == ', name)
        let {
          options
        } = this.paymentConfig;
        let itemData = options[name][index];



        console.log('选择支付方式 itemData == ', itemData)

        if (itemData.id == 6) {
          let msg = `订单从${itemData.name}，是否确认支付？`;
          if (this.mapOptions.price) {
            msg = `订单从${itemData.name}${this.mapOptions.price}元，是否确认支付？`
          }
          this.myDialog = {
            data: itemData,
            visible: true,
            options: {
              title: `${itemData.name}`,
              // is_title: false, // 是否显示标题
              message: msg,
              is_tips: false,
              sign: 'pay-way',
            },
          }
          return;
        }

        // 告知弹窗变化
        this.informChangeFun({
          visible: false,
          status: 1, // 1/正常 2/关闭 3/其他
          message: '正常',
          data: itemData,
        });
      },
      /**
       * 获取页面数据
       */
      getPageDataFun() {
        // 获取支付方式
        this.getModeOfPaymentDataFun();
      },
      /**
       * 获取支付方式
       */
      getModeOfPaymentDataFun() {
        return new Promise((resolve, reject) => {
          if (!this.paymentConfig.loading) {
            // this.myGetPayModeAuthority({
            this.myGetPayModeAuthorityTwo({
              type: this.type,
            }).then((res) => {
              console.log('获取支付方式 res == ', res)
              let resData = res.data.data;
              this.paymentConfig.options = resData;
              this.paymentConfig.loading = true;
              console.log('获取支付方式 this.paymentConfig == ', this.paymentConfig)
              resolve(resData);
            })
          }
        })
      },
      /**
       * 初始化对话框
       */
      initMyDialogFun() {
        this.myDialog = {
          data: {},
          visible: false,
          options: {},
        }
      },
      /**
       * 对话框回调
       */
      myDialogChangeFun(e) {
        console.log('对话框回调 e == ', e);
        let {
          data: payData
        } = this.myDialog;
        let {
          visible,
          status,
          message,
          data,
          name,
          sign
        } = e;
        if (status == 1) { // 确认

          // 告知弹窗变化
          this.informChangeFun({
            visible: false,
            status: 1, // 1/正常 2/关闭 3/其他
            message: '正常',
            data: payData,
          });

          // 初始化对话框
          this.initMyDialogFun();

        } else { // 取消
          // 初始化对话框
          this.initMyDialogFun();
        }
      },
    },
    // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
    created() {

    },
    // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
    mounted() {
      // 获取页面数据
      this.getPageDataFun();
    },
    beforeCreate() { // 创建前

    },
    beforeMount() { // 挂载前

    },
    beforeUpdate() { // 更新前

    },
    updated() { // 更新后

    },
    beforeDestroy() { // 销毁前

    },
    destroyed() { // 销毁后

    },
    activated() { // 缓存保持，需要由 keep-alive 触发

    },
  }

</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
  .container {
    box-sizing: border-box;

    &::v-deep {
      .el-dialog {
        border-radius: 10px;
      }

      .el-dialog__header {
        padding: 0;

        .el-dialog__title {
          display: none;
        }
      }

      // .el-dialog__body {
      //   padding: 50px 20px;
      // }
      .el-dialog__headerbtn {
        width: 24px;
        height: 24px;
        text-align: center;
        font-size: 24px;
        top: 10px;
        right: 10px;
      }
    }
  }

  // .select-pay-method {
  //   box-sizing: border-box;
  //   padding-top: 10px;

  //   .spm-title {
  //     text-align: center;
  //     font-size: 18px;
  //     color: #222;
  //   }

  //   .spm-list,
  //   .spn-options {
  //     margin-top: 24px;

  //     .sl-item,
  //     .so-li {
  //       display: block;
  //       width: 150px;
  //       margin: 10px auto 0;

  //       &::v-deep {
  //         .fake-btn {
  //           width: 100%;
  //           height: 48px;
  //           padding: 0;
  //           font-weight: initial;
  //           font-size: 12px;
  //           color: #adadad;
  //           border: 1px solid #adadad;
  //           background: #fff;
  //           outline: none;

  //           .icon {
  //             display: flex;
  //             align-items: center;
  //             justify-content: center;

  //             .icon-box {
  //               margin-right: 12px;

  //               .iconfont {
  //                 font-size: 20px;

  //                 &.icon-weixinzhifu {
  //                   color: #1aad19;
  //                 }

  //                 &.icon-zhifubaozhifu {
  //                   color: #1477fe;
  //                 }
  //               }
  //             }

  //             .icon-text {
  //               text-align: left;
  //               min-width: 60px;
  //             }
  //           }

  //           &.el-button:hover,
  //           &.el-button:focus,
  //           &.el-button:active {
  //             color: none;
  //             border-color: none;
  //             background-color: none;
  //           }

  //           &.el-button:active {
  //             color: $active-default-color;
  //             border-color: $active-default-color;
  //           }
  //         }
  //       }
  //     }
  //   }

  //   .spm-list {
  //     .sl-item {
  //       .icon-box {
  //         $sizes: 30px;
  //         box-sizing: border-box;
  //         border-radius: 50%;
  //         overflow: hidden;
  //         width: $sizes;
  //         height: $sizes;

  //         .fake-img {
  //           width: 100%;
  //           height: 100%;
  //           font-size: 0;
  //         }
  //       }

  //       .icon-text {
  //         text-align: left;
  //         min-width: 0;
  //       }
  //     }
  //   }
  // }


  .select-pay-method {
    box-sizing: border-box;
    padding-top: 10px;

    .spm-title {
      text-align: center;
      font-size: 18px;
      color: #222;
    }

    .spm-box {
      margin-top: 24px;

      &:first-of-type {
        margin-top: 0;
      }

      .spm-box-title {
        font-size: 16px;
        color: #808080;
      }

      .spm-head {}
    }

    .spm-list,
    .spn-options {
      margin-top: 24px;

      .sl-item,
      .so-li {
        display: block;
        // width: 150px;
        margin: 10px auto 0;

        &::v-deep {
          .fake-btn {
            width: 100%;
            height: 48px;
            padding: 0;
            font-weight: initial;
            font-size: 12px;
            color: #adadad;
            border: 1px solid #adadad;
            background: #fff;
            outline: none;

            .icon {
              display: flex;
              align-items: center;
              // justify-content: center;

              .icon-box {
                // margin-right: 12px;
                margin: 0 12px;

                .iconfont {
                  font-size: 20px;

                  &.icon-weixinzhifu {
                    color: #1aad19;
                  }

                  &.icon-zhifubaozhifu {
                    color: #1477fe;
                  }
                }
              }

              .icon-text {
                text-align: left;
                min-width: 60px;

                .price-text {
                  color: #d42a20;
                }
              }
            }

            &.el-button:hover,
            &.el-button:focus,
            &.el-button:active {
              color: none;
              border-color: none;
              background-color: none;
            }

            &.el-button:active {
              color: $active-default-color;
              border-color: $active-default-color;
            }
          }
        }
      }
    }

    .spm-list {
      margin-top: 0;

      .sl-item {
        .icon-box {
          $sizes: 30px;
          box-sizing: border-box;
          border-radius: 50%;
          overflow: hidden;
          width: $sizes;
          height: $sizes;

          .fake-img {
            width: 100%;
            height: 100%;
            font-size: 0;
          }
        }

        .icon-text {
          text-align: left;
          min-width: 0;
        }
      }
    }
  }

</style>
