<template>
  <div class="cropper-container">
    <div class="cropper-module">
      <div class="cm-main">
        <vue-cropper
          ref="cropper"
          :img="initImages"
          :outputSize="mapOption.outputSize"
          :outputType="mapOption.outputType"
          :info="mapOption.info"
          :canScale="mapOption.canScale"
          :autoCrop="mapOption.autoCrop"
          :autoCropWidth="mapOption.autoCropWidth"
          :autoCropHeight="mapOption.autoCropHeight"
          :fixed="mapOption.fixed"
          :fixedNumber="mapOption.fixedNumber"
          :full="mapOption.full"
          :fixedBox="mapOption.fixedBox"
          :canMove="mapOption.canMove"
          :canMoveBox="mapOption.canMoveBox"
          :original="mapOption.original"
          :centerBox="mapOption.centerBox"
          :height="mapOption.height"
          :infoTrue="mapOption.infoTrue"
          :maxImgSize="mapOption.maxImgSize"
          :enlarge="mapOption.enlarge"
          :mode="mapOption.mode"
          @realTime="realTime"
          @imgLoad="imgLoad">
        </vue-cropper>
      </div>
      <div class="cm-rest">
        <!-- 预览效果图 -->
        <div class="show-preview">
          <div :style="previews.div" class="preview">
            <img :src="previews.url" :style="previews.img">
          </div>
        </div>
        <!--预览效果图 end -->

        <!-- 控件 -->
        <div class="cm-control">
          <ul class="control-list">
            <li class="cl-item">
              <el-button size="mini" type="danger" plain icon="el-icon-zoom-in" @click="changeScale(1)">放大</el-button>
            </li>
            <li class="cl-item">
              <el-button size="mini" type="danger" plain icon="el-icon-zoom-out" @click="changeScale(-1)">缩小</el-button>
            </li>
            <li class="cl-item">
              <el-button size="mini" type="danger" plain @click="rotateLeft">↺ 左旋转</el-button>
            </li>
            <li class="cl-item">
              <el-button size="mini" type="danger" plain @click="rotateRight">↻ 右旋转</el-button>
            </li>
          </ul>
          <ul class="main-contron">
            <li class="mc-item">
              <el-button size="mini" type="success" @click="confirmEventFun">保存</el-button>
            </li>
            <li class="mc-item cancel">
              <el-button size="mini" type="success" @click="cancelEventFun">取消</el-button>
            </li>
          </ul>
        </div>
        <!-- 控件 end -->

      </div>
    </div>
  </div>
</template>

<script>
import {
  dataURLToBlob, // 将base64转换为blob
  blobToFile, // 将blob转换为file
  blobToImage, // 将Blob转image
  imageToBlob, // image转Blob
} from '@/utils/utils_fun.js';
import { VueCropper } from 'vue-cropper';
export default {
  // 允许组件模板递归地调用自身
  name: '',
  // 声明一组可用于组件实例中的组件
  components: {
    VueCropper
  },
  // 一个用于从父组件接收数据的数组或对象
  props: {
    initImages: String,
    option: {
      type: Object,
      default: () => {},
    }
  },
  // 该函数返回组件实例的 data 对象
 data() {
    return {
      name: 'aa',
      previews: {},
      mapOption:{
        img: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F201903%2F30%2F20190330192531_jKAKG.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1637461727&t=36de5322e6ccd98c8eb84661088f6a35',             //裁剪图片的地址
        outputSize: 1,       //裁剪生成图片的质量(可选0.1 - 1)
        outputType: 'jpeg',  //裁剪生成图片的格式（jpeg || png || webp）
        info: true,          //图片大小信息
        canScale: true,      //图片是否允许滚轮缩放
        autoCrop: true,      //是否默认生成截图框
        // autoCropWidth: 300,  //默认生成截图框宽度
        // autoCropHeight: 220, //默认生成截图框高度
        autoCropWidth: 500,  //默认生成截图框宽度
        autoCropHeight: 367, //默认生成截图框高度
        fixed: true,         //是否开启截图框宽高固定比例
        fixedNumber: [1.366, 1], //截图框的宽高比例
        full: true,         //按原比例裁切图片，不失真
        fixedBox: true,      //固定截图框大小，不允许改变
        canMove: false,      //上传图片是否可以移动
        canMoveBox: true,    //截图框能否拖动
        original: false,     //上传图片按照原始比例渲染
        centerBox: false,    //截图框是否被限制在图片里面
        height: true,        //是否按照设备的dpr 输出等比例图片
        infoTrue: false,     //true为展示真实输出图片宽高，false展示看到的截图框宽高
        maxImgSize: 3000,    //限制图片最大宽度和高度
        enlarge: 1,          //图片根据截图框输出比例倍数
        mode: 'contain'  //图片默认渲染方式
      }
    };
  },
  // 计算属性：
  computed: {

  },
  // 钩子函数--侦听data变动：
  watch: {
    // option: function(e) {
    //   this.initOptionsFun(e)
    // }
  },
  // 在实例创建完成后被立即同步调用
  methods: {
    /**
     * 初始化选项数据
     */
    initOptionsFun(value) {
      this.mapOption = Object.assign(this.mapOption,value);
      console.log('this.mapOption == ',this.mapOption)
    },
    //初始化函数
    imgLoad (msg) {
      console.log("工具初始化函数====="+msg)
    },
    /**
     * 确认事件
     */
    confirmEventFun() {
      this.getTailorImagesFun().then((file) => {

        // 告知裁剪结果
        // this.informTailorResultFun({
        //   status: 1,
        //   message: '裁剪成功',
        //   data: file,
        // });

        // 获取oss签名
        this.myGetOssSignature(file).then((res) => {
          console.log('oss 签名回调 == ',res);
          let { path, key, policy, OSSAccessKeyId, accessid, signature } = res;
          let param = {
            key,
            policy,
            OSSAccessKeyId,
            signature,
          };
          console.log('param == ',param)
          this.myUploadFileToServers(param,file).then((res) => {
            // 告知裁剪结果
            this.informTailorResultFun({
              status: 1,
              message: '裁剪成功',
              data: path,
            });
          });
        })
      });
    },
    /**
     * 取消事件
     */
    cancelEventFun() {
      // 告知裁剪结果
      this.informTailorResultFun();
    },
    /**
     * 告知裁剪结果
     */
    informTailorResultFun(opt) {
      let data = {
        status: 2, // 1/裁剪成功 2/取消裁剪 3/取消裁剪，关闭弹窗
        message: '取消裁剪',
        data: '',
      };

      let result = Object.assign(data,opt)
      this.$emit('tailor-change',result);
    },
    /**
     * 获取裁剪的图片
     */
    getTailorImagesFun () {
      return new Promise((resolve,reject) => {
        this.$refs.cropper.getCropData(data => {

          // resolve(data)
          // 将base64转换为blob
          let blob = dataURLToBlob(data);

          // 将blob转换为file
          let file = blobToFile(blob,data);
          console.log('获取裁剪的图片 file == ',file);
          resolve(file)
        })
        // this.$refs.cropper.getCropBlob(data => {
        //   // do something
        //   console.log('获取裁剪的图片 data == ',data);

        // })
        // this.$message({
        //   message: '文件服务异常，请联系管理员！',
        //   type: "error"
        // });
      })
    },
    //图片缩放
    changeScale (num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },
    //向左旋转
    rotateLeft () {
      this.$refs.cropper.rotateLeft()
    },
    //向右旋转
    rotateRight () {
      this.$refs.cropper.rotateRight()
    },
    //实时预览函数
    realTime(data) {
      var previews = data
      var h = 0.5
      var w = 0.2

      this.previewStyle1 = {
        width: previews.w + "px",
        height: previews.h + "px",
        overflow: "hidden",
        margin: "0",
        zoom: h
      }

      this.previewStyle2 = {
        width: previews.w + "px",
        height: previews.h + "px",
        overflow: "hidden",
        margin: "0",
        zoom: w
      }

      // 固定为 100 宽度
      this.previewStyle3 = {
        width: previews.w + "px",
        height: previews.h + "px",
        overflow: "hidden",
        margin: "0",
        zoom: 100 / previews.w
      }

      // 固定为 100 高度
      this.previewStyle4 = {
        width: previews.w + "px",
        height: previews.h + "px",
        overflow: "hidden",
        margin: "0",
        zoom: 100 / previews.h
      }
      // this.previews = data
      this.previews = previews
    },
    //选择图片
    selectImg (e) {
      let file = e.target.files[0]
      if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
        this.$message({
          message: '图片类型要求：jpeg、jpg、png',
          type: "error"
        });
        return false
      }
      //转化为blob
      let reader = new FileReader()
      reader.onload = (e) => {
        let data
        if (typeof e.target.result === 'object') {
          data = window.URL.createObjectURL(new Blob([e.target.result]))
        } else {
          data = e.target.result
        }
        this.option.img = data
      }
      //转化为base64
      reader.readAsDataURL(file)
    },
  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {

  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {
    // 初始化选项数据
    this.initOptionsFun(this.option);
  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前

  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
.cropper-container {
  box-sizing: border-box;
}

.cropper-module {
  $gutter: 20px; // 间隔
  display: flex;
  .cm-main {
    flex: 1;
    min-width: 0;
    // min-height: 461px;
    height: 610px;
  }
  .cm-rest {
    width: 502px;
    margin-left: $gutter;
    .cm-control {
      width: 300px;
      margin: 0 auto;
      .control-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .cl-item {
          width: calc(50% - (#{$gutter} / 2));
          margin-top: $gutter;
        }
      }
      .main-contron {
        .mc-item {
          margin-top: $gutter;
          &.cancel {
            .el-button {
              background: #fff;
              color: #809799;
              &::v-deep {
                &.el-button:hover,&.el-button:focus,&.el-button:active {
                  border-color: #85ce61;
                  background-color: #fff;
                  outline: none;
                }
              }
            }
          }
          .el-button {
            &::v-deep {
              &.el-button:hover,&.el-button:focus,&.el-button:active {
                border-color: #85ce61;
                background-color: #85ce61;
                outline: none;
              }
            }
          }
        }
      }
      &::v-deep {
        .el-button {
          width: 100%;
          height: 40px;
          line-height: 40px;
          padding: 0;
        }
      }
    }
  }
}

.footer-btn{
  margin-top: 30px;
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  .scope-btn{
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    padding-right: 10px;
  }
  .upload-btn{
    flex: 1;
    -webkit-flex: 1;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
  }
  .btn {
    outline: none;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    -webkit-appearance: none;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: 0;
    -webkit-transition: .1s;
    transition: .1s;
    font-weight: 500;
    padding: 8px 15px;
    font-size: 12px;
    border-radius: 3px;
    color: #fff;
    background-color: #409EFF;
    border-color: #409EFF;
    margin-right: 10px;
  }
}

.show-preview{
  flex: 1;
  -webkit-flex: 1;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  .preview{
    overflow: hidden;
    border:1px solid #67c23a;
    background: #cccccc;
  }
}
</style>
