import Vue from 'vue'


// 支付组件
import myPayDialog from '@/components/my-pay-dialog/MyPayDialog.vue';
import selectPayMethod from '@/components/my-pay-dialog/template/select.pay.method.vue'
import MyDialog from '@/components/my-dialog/index.js'

Vue.component('my-pay-dialog', myPayDialog)
Vue.component('select-pay-method', selectPayMethod)
Vue.component('select-pay-method', selectPayMethod)
Vue.use(MyDialog)
// 支付组件end
